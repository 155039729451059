import React from "react";
import { Salutation } from "../pages/dashboard";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiLogoWhatsapp } from "react-icons/bi";
import { useGlobalContext } from "../utills/store";

const Agent =({Message})=>{
  const { user } = useGlobalContext();

    return( 
        <div className="MainDash" style={{ padding: "0 20px" }}>
        <p style={{ color: "#08384e", fontSize: 20, marginTop: "4rem", width: "100%", textAlign: "left" }}>
          {Salutation()},<span style={{ color: "black" }}>  {user?.fullName?.split(" ")[0]} !</span>
        </p>
        <p><b>{Message}</b></p>

        <div style={{display:'flex',justifyContent:'center',alignItems:"center",alignContent:'center',height:"60vh"}}>
          <div className="glass agent-wrapper">
            <div className="agent-writeup">
            <MdOutlineSupportAgent color="green" fontSize={"5rem"}/>
            <p style={{fontSize:"24px"}}>Chat with an agent</p>
            <button onClick={()=>window.open(`https://wa.me/+2348100027470?text=${encodeURIComponent('hello')}`,'_blank')} style={{border:"none",borderRadius:"10px",width:"100%",height:"50px",background:"black",color:"white"}}>
            <BiLogoWhatsapp color="green" fontSize={"2rem"}/>
              Open whatsapp
              </button>
            </div>
           
          </div>
        </div>
        </div>
    )
}

export default Agent