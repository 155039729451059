// PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-conditions" style={{marginTop:100}}>
      <h1 style={{textAlign:"center",fontSize:"20px",padding:"20px 0"}}>Privacy Policy</h1>
      <p>
        EASY-COMPLY APP is a product of Easy-Comply Limited (“E-C L”). As the data
        collector, E-C L ("we" “us” “our”) are committed to protecting and respecting your
        privacy. This policy sets out the basis on which any personal data we collect from you,
        or that you provide to us, will be processed by us. Please read the following carefully
        to understand our views and practices regarding your personal data and how we will
        treat it. By visiting "https://easycomply.org" (“our site”) you are accepting and
        consenting to the practices described in this policy. The collection and processing of
        your personal data is in accordance with the National Information Technology
        Development Agency Act 2007 (the “Act”), Nigeria Data Protection Regulation 2019
        (the “Regulations”) and the provisions and prescriptions of Section 5; Part 1 and Part
        2 of National Information Systems and Network Security Standards and Guidelines.
      </p>
      <h2 style={{fontSize:"18px"}}>Information we may collect from you</h2>
      <p>
        We may collect and process the following data about you:
      </p>
      <ul>
        <li>
          <strong>Information you give us:</strong> You may provide information by filling in forms on our site
          https://easycomply.org or by corresponding with us through various channels. This may include your
          name, address, email address, phone number, login information, financial details, and other relevant information.
        </li>
        <li>
          <strong>Information we collect about you:</strong> During your visits to our site, we may automatically collect
          technical information, browsing behavior, and anonymized repayment data.
        </li>
        <li>
          <strong>Information we receive from others:</strong> We may receive information about you from other websites we operate
          or from third parties, including business partners, regulatory authorities, and service providers.
        </li>
      </ul>

      <h2 style={{fontSize:"18px"}}>Cookies</h2>
      <p>
        Our website uses cookies to distinguish you from other users and enhance your browsing experience. By visiting our
        website, you consent to the placement of cookies and beacons in your browser and HTML-based emails in accordance with
        this Privacy Policy.
      </p>

      <h2 style={{fontSize:"18px"}}>Uses made of the information</h2>
      <p>
        We use the information we collect about you in various ways, including but not limited to:
      </p>
      <ul>
        <li>
          Determining whether to provide a service to you on a lawful basis, contract performance, consent, legal obligation.
        </li>
        <li>
          Investigating and resolving any complaints or issues you may have, relying on contract performance and consent.
        </li>
        <li>
          Exercising our rights under contracts, recovering payments, and fulfilling legal, compliance, regulatory, and risk management obligations.
        </li>

      <h2 style={{fontSize:"18px"}}>Uses made of the information (continued)</h2>
      <ul>
        <li>
          <strong>Notifying you about changes:</strong> We may use your information to notify you about changes to our standard terms of service or to our business.
        </li>
        <li>
          <strong>Ensuring effective presentation:</strong> To ensure that content from our site is presented in the most effective manner for you and your device.
        </li>
      </ul>

      <h2 style={{fontSize:"18px"}}>Information we collect about you (continued)</h2>
      <ul>
        <li>
          <strong>Further processing of your data:</strong> We may carry out further processing of your data for archiving purposes in the public interest, compliance, scientific, or historical research purposes, or statistical purposes.
        </li>
      </ul>

      <h2 style={{fontSize:"18px"}}>Disclosure of your information</h2>
      <p>
        We may share your personal information with any member of our company and relevant regulatory authorities. Selected third parties may also include business partners, institutions, regulators, suppliers, subcontractors, advertisers, advertising networks, analytics and search engine providers, and others as necessary for the performance of any contract we enter into with them or you in relation to the service you engaged us for.
      </p>

      <h2 style={{fontSize:"18px"}}>By using the Easy-Comply Website Application</h2>
      <p>
        By using the Easy-Comply Website Application, you agree to the "Terms and Conditions", adopting them as your consent. You also agree that we are authorized to share, receive, and use data/information collected from your transaction with other affiliated third parties, including regulatory authorities, compliance institutions, companies, aggregators, credit bureaus, other financial institutions, e-commerce platforms, etc.
      </p>

      <h2 style={{fontSize:"18px"}}>Where we store your personal data</h2>
      <p>
        The data we collect is stored on our secure servers. If you have a password enabling access to certain parts of our site, you are responsible for keeping it confidential. We advise against sharing passwords. While we strive to protect your data, we cannot guarantee the security of data transmitted to our site. Transmission is at your own risk. Once we receive your information, strict procedures and security features are used to try to prevent unauthorized access.
      </p>

      <h2 style={{fontSize:"18px"}}>Data retention</h2>
      <p>
        Unless a longer retention period is required or permitted by law, we will only hold your data on our systems for the period necessary to fulfill the purposes outlined in this privacy policy. Even if we delete your data, it may persist on backup or archival media for legal, tax, or regulatory purposes.
      </p>

      <h2 style={{fontSize:"18px"}}>Your rights</h2>
      <p>
        You have the right to ask us not to process your personal data for marketing purposes and to withdraw your consent at any time by ceasing usage of the Web App and deleting your account. Please note that the withdrawal of your consent will not affect the lawful processing of data which we have obtained based on your previous consent. You can exercise the right at any time by contacting us using the complaint tab on our web portal.
      </p>

      <h2 style={{fontSize:"18px"}}>Access to information</h2>
      <p>
        The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act. Any access request may be subject to a fee in providing you with details of the information we hold about you.
      </p>

      <h2 style={{fontSize:"18px"}}>Remedies</h2>
      <p>
        Each of the parties will be entitled to enforce its rights in the event of a breach of the terms of this privacy policy, to recover damages caused by any breach of the provisions herein and to exercise all other rights existing under law. Any claim or dispute arising in relation to this privacy policy shall be subject to the jurisdiction of the courts of the Federal Republic of Nigeria. We shall not be liable for any breach where a claim in respect of such breach is not brought within one month of the date that such breach arose.
      </p>

      <h2 style={{fontSize:"18px"}}>Limitation of liability</h2>
      <p>
        Notwithstanding any other provision in this privacy policy, neither E-C L, its affiliates, officers, directors, employees, attorneys, or agents shall have any liability with respect to, and you hereby waive, release, and agree not to sue any of them upon, any claim for any special, indirect, incidental, consequential damages suffered or incurred by you in connection with, arising out of, or in any way related to, a breach of this privacy policy.
      </p>

      <h2 style={{fontSize:"18px"}}>Updates to this policy</h2>
      <p>
        We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy.
      </p>
      </ul>

  


    </div>
  );
};

export default PrivacyPolicy;
