import React from 'react';
import axios from 'axios';

// Create a new Axios instance

const axiosInstance = axios.create({
    baseURL: 'https://server.easycomply.org/api/v1', // Set your base URL here
    // timeout: 2000, // Set your timeout value (in milliseconds) here
    headers: {
        'Content-Type': 'application/json', // Set your default headers here
    },
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage right before the request is sent
        const token = localStorage.getItem("tok_xx");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // You can modify the response data here
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;