import React, { useState } from 'react';
import {Modal, TextField, Button, Typography, Input, Box, Select, MenuItem } from '@mui/material';
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from '../pages/dashboard';
import { toast } from 'react-toastify';
import axiosInstance from '../utills/axios';
import { useGlobalContext } from '../utills/store';
import Admin from '../pages/dashboard/admin';


const initialState = {
  incorporationDocuments: null,
  policeClearance: null,
  passportPhotos: null,
  taxPaymentEvidence: null,
  bankReferenceLetter: null,
  businessState: '',
  cacCertificationFile: null,
  memartFile: null,
  statusReportFile: null,
  contactPhone:'',
  contactEmail:''
}
const MoneyLenderLicenseForm = () => {
  const [formState, setFormState] = useState(initialState);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactErrormessage, setContactErrormessage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useGlobalContext();

  const statesArray = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Benue", "Borno", "Bayelsa",
    "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Federal Capital Territory",
    "Gombe", "Imo", "Jigawa", "Kebbi", "Kaduna", "Kano", "Kogi", "Katsina", "Kwara",
    "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers",
    "Sokoto", "Taraba", "Yobe", "Zamfara"
  ];

  const handleFilePick = (stateKey) => (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setFormState({ ...formState, [stateKey]: file });
    } else {
      setErrorMessage('Only PDF files are allowed');
    }
  };

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = async() => {
    // Validate that all fields are filled
    const { incorporationDocuments, policeClearance, passportPhotos, taxPaymentEvidence, bankReferenceLetter, businessState, cacCertificationFile, memartFile, statusReportFile } = formState;

    if (!incorporationDocuments || !policeClearance || !passportPhotos || !taxPaymentEvidence || !bankReferenceLetter || !cacCertificationFile || !memartFile || !statusReportFile || !businessState) {
      setErrorMessage('All fields are required');
      return;
    }
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('/money-lender-license', formState, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setIsLoading(false)
      toast.success(response.data.message);
      setFormState(initialState);
      setOpenModal(false);
    } catch (error) {
      setIsLoading(false)
      setContactErrormessage('Error submitting form');
      toast.error(error.message)
    }

    // Create FormData object to handle file uploads
  

    // Submit the form data
   
  };

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formState).some(
      key => key !== 'contactEmail' && key !== 'contactPhone' && !formState[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }
   
    setOpenModal(true);
  };

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
    { user.role === "admin" ? <Admin service={"Money lender’s license"} route={"money-lender-license"}/> : <>
    <p style={{ color: "#08384e", fontSize: 20, marginTop: "4rem", width: "100%", textAlign: "left" }}>{Salutation()},<span style={{ color: "black" }}> {(user?.fullName?.split(" ")[0])} !</span> </p>
      <p><b>{"Requirements for Money lender’s license Form below."}</b></p>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Contact Information
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Phone"
              type="tel"
              name="contactPhone"
              variant="outlined"
              value={formState.contactPhone}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <TextField
              label="Email"
              type="email"
              name="contactEmail"
              variant="outlined"
              value={formState.contactEmail}
              onChange={handleChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
             {contactErrormessage ? (
          <Typography variant="body2" color="error">
            {contactErrormessage}
          </Typography>
        ) : null}

            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading}
              style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
            >
              {isLoading ? 'Please wait...' : 'Proceed'}
            </Button>
           
            <p style={{marginTop:"20px"}}>Note: An agent will be assigned to you shortly.</p>
          </div>
        </Box>
      </Modal>
      <Box sx={{ padding: '0px' }}>
        <p>1.  CAC Incorporation documents of the company (Note that money lending must be part of the object of the company):</p>
        <Input fullWidth type="file" onChange={handleFilePick('incorporationDocuments')} />

        <p style={{ marginTop: "25px" }}>2. Police clearance certificates of the directors of the company:</p>
        <Input fullWidth type="file" onChange={handleFilePick('policeClearance')} />

        <p style={{ marginTop: "25px" }}>3. Passport Photographs of directors of the company:</p>
        <Input fullWidth type="file" onChange={handleFilePick('passportPhotos')} />

        <p style={{ marginTop: "25px" }}>4. Evidence of tax payment for all directors (For immediate past 3 years):</p>
        <Input fullWidth type="file" onChange={handleFilePick('taxPaymentEvidence')} />

        <p style={{ marginTop: "25px" }}>5. Reference letter from the applicant’s bank:</p>
        <Input fullWidth type="file" onChange={handleFilePick('bankReferenceLetter')} />

        <p style={{ marginTop: "25px" }}>Upload CAC Documents:</p>

        <p style={{ marginTop: 50 }}>CAC Certificate (PDF):</p>
        <Input type="file" fullWidth onChange={handleFilePick('cacCertificationFile')} />

        <p style={{ marginTop: 20 }}>Memart (PDF):</p>
        <Input type="file" fullWidth onChange={handleFilePick('memartFile')} />

        <p style={{ marginTop: 20 }}>Status Report (PDF):</p>
        <Input type="file" fullWidth onChange={handleFilePick('statusReportFile')} />

        <label style={{ marginTop: "30px" }}>Select state in which you carry out business?</label>
        <Select
          name="businessState"
          value={formState.businessState}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {statesArray.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>

        {errorMessage && <Typography variant="body2" color="error" style={{ margin: "10px 0" }}>{errorMessage}</Typography>}

        <div style={{ display: "flex", alignItems: "center", margin: "30px 0 " }}>
          <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} />
          <p style={{ padding: 0, margin: 0, color: "gray" }}>1. The officials from the Ministry of Home Affairs will conduct a physical inspection of the applicant’s place of business.</p>
        </div>

        <p style={{ padding: 0, marginLeft: 20, color: "gray" }}>2. Minimum share capital must not be less than 20,000,000 (Twenty Million Naira).</p>
        <p style={{ paddingTop: 10, marginLeft: 20, color: "gray" }}>3. There must be at least 2 directors.</p>
        <p style={{ padding: 0, marginLeft: 20, color: "gray" }}>4. If the above are not contained in your company registration documents, kindly visit our post-incorporation service page to carry out the process.</p>
        <p style={{ padding: 0, marginLeft: 20, color: "gray" }}>5. Object clause in Memorandum of Association (MEMART) must include provision of money lending services.</p>

        <Button variant="contained" onClick={HandleModal  } style={{ margin: '30px 0', background: "var(--nyd-secondary)" }}>
          Submit
        </Button>
      </Box></>}
    </div>
  );
};

export default MoneyLenderLicenseForm;
