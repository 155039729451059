import React from 'react';
import { formatDate } from '../pages/dashboard/admin';

// Function to recursively render object content
const RenderObject = (obj) => {
  const renderValue = (key, value) => {
    if (['_id', '__v', 'createdAt', 'updatedAt'].includes(key)) {
        return null; // Ignore these keys
      }
      if (key === 'dob') {
        // Format DOB to dd-mm-yy
        const formattedDOB = formatDate(value);
        return (
          <div key={key} style={{ marginLeft: '20px' }}>
            <strong>{key}:</strong> {formattedDOB}
          </div>
        );
      }
    if (Array.isArray(value)) {
      return (
        <div key={key} style={{ marginLeft: '20px' }}>
          <strong>{key}:</strong>
          <div>{value.map((item, index) => renderValue(`${key} [${index + 1}]`, item))}</div>
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div key={key} style={{ marginLeft: '20px' }}>
          <strong>{key}:</strong>
          <div>{RenderObject(value)}</div>
        </div>
      );
    } else if (typeof value === 'string' && value.startsWith('data:image')) {
      return (
        <div key={key} style={{ marginLeft: '20px' }}>
          <strong>{key}:</strong>
          <img src={value} alt={key} style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </div>
      );
    } else if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('https'))) {
      return (
        <div key={key} style={{ marginLeft: '20px' }}>
          <strong>{key}:</strong>
          <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
        </div>
      );
    } else {
      return (
        <div key={key} style={{ marginLeft: '20px' }}>
          <strong>{key}:</strong> {value?.toString()}
        </div>
      );
    }
  };

  return Object.keys(obj).map(key => renderValue(key, obj[key]));
};

export default RenderObject;