// AboutUsPage.js

import React from 'react';
import logo from "../assets/logo_.PNG"
const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      <div className="image-container">
      <div className="image-wrapper">
        <img src={logo} alt="About Us" style={{width:"100%",height:"auto"}} />
      </div>
      </div>
      <div className="text-container">
        <h2>About Us</h2>
      <div className="compliance-welcome">
      <h2>Welcome to EasyComply – Your Trusted Partner in Compliance Solutions!</h2>
      <p>
        At EasyComply, we are on a dedicated mission to revolutionize the landscape of compliance software,
        simplifying the intricacies of online regulatory adherence. Our unwavering commitment is to empower
        our valued customers, providing them with robust tools to achieve and maintain regulatory compliance seamlessly.
      </p>

      <div className="highlight-section">
        <h3 className='compliance-welcome'>Who We Are:</h3>
        <p>
          EasyComply stands proudly as a leading compliance company, with its roots firmly planted in Nigeria.
          We specialize in crafting cutting-edge solutions tailored to meet the dynamic compliance, needs of businesses
          operating in the digital realm.
        </p>
      </div>

      <div className="highlight-section">
        <h3 className='compliance-welcome'>Our Mission:</h3>
        <p>
          Our primary mission is to redefine the way businesses approach compliance. We understand that navigating
          the intricate web of regulations can be daunting, and that's why we're here to make it simple for you.
          EasyComply is dedicated to developing innovative compliance software that not only meets the highest industry
          standards but also ensures that our customers can navigate the complex regulatory landscape with ease.
        </p>
      </div>

      <div className="highlight-section">
        <h3 className='compliance-welcome'>Why Choose EasyComply:</h3>
        <p>
          <strong>Simplicity in Complexity:</strong> We believe that compliance should not be a hindrance to your business.
          Our solutions are designed with a user-friendly interface, making compliance processes straightforward and efficient.
        </p>
        <p>
          <strong>Tailored for Your Business:</strong> At EasyComply, we recognize that each business is unique.
          Our software is customizable to suit the specific compliance needs of your industry, ensuring a personalized
          and effective approach to regulatory adherence.
        </p>
        <p>
          <strong>Nigerian Roots, Global Expertise:</strong> As a Nigerian-based company, we bring a deep understanding
          of the local regulatory environment. Combined with our global expertise, we offer comprehensive solutions
          that transcend geographical boundaries.
        </p>
        <p>
          <strong>Continuous Innovation:</strong> The compliance landscape is ever-evolving. EasyComply stays ahead
          of the curve by continuously innovating and updating our software to align with the latest regulatory requirements.
        </p>
      </div>

      <div className="highlight-section">
        <h3 className='compliance-welcome'>Our Commitment:</h3>
        <p>
          EasyComply is not just a service provider; we are your committed partner in the journey towards regulatory excellence.
          We understand the challenges businesses face in today's fast-paced world, and our goal is to alleviate the compliance burden,
          allowing you to focus on what you do best – growing your business.
        </p>
        <p>
          Join us at EasyComply and experience a new era of simplified compliance solutions. Your success is our mission,
          and compliance is made easy with us!
        </p>
      </div>
    </div>
    </div>
    </div>
  );
};

export default AboutUsPage;
