import React, { useEffect,useState } from  "react"
import Sidebar from "../../components/sideBar"
import {Route, useNavigate } from 'react-router-dom';

const Layout = ({children,authed})=>{
    const Navigate = useNavigate()
    const [showSideBar,setShowSidebar] = useState(true)
    useEffect(()=>{
     (   ()=>{

            !authed  && Navigate("/login")
        })()

    },[])
    return(
        <div style={{display:"flex",width:"100%",height:"auto"}}>
            <Sidebar showSideBar={showSideBar} setShowSidebar={setShowSidebar}/>
            <div className="right_dash" style={{width:"100%",minHeight:"100vh"}} onClick={()=>{setShowSidebar(false)}}>
             {children}  
             </div>
        </div>
    )
}
export default Layout