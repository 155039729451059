import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { SiGnuprivacyguard } from "react-icons/si";
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../utills/store";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import axios from  "axios"
const Login = () => {
  const { setIsLoggedIn ,setUser} = useGlobalContext();
  const navigate = useNavigate()


  const [state, setState] = useState({
    email:"",
    password:""
  })
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const HandleSubmit= async()=>{
    setLoading(true)
    setError("")
    try {
      if(state.email !== "" && state.password !== ""){
      let response = await axios.post("https://server.easycomply.org/login",{...state},{
      headers:{
        "Content-Type":"application/json", 
      }
       })
       localStorage.setItem("userxx",JSON.stringify(response.data.user))
       localStorage.setItem("tok_xx",response.data.token)
       setLoading(false)
       setUser(response.data.user);
       setIsLoggedIn()
       navigate("/dashboard")
  }else{
    setLoading(false)
      setError("All filed are required");
    }
    } catch (error) {
      toast.error(error?.response?.data ? error.response.data : error.message)
      setLoading(false)
    }
   


  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
    <section className="nyd-page-wrapper my-center-item __wrapper" style={{marginTop:100}}>
        <div className="my-center-item my-form-wrapper" style={{width:"60%",marginBottom:40}}>
        <div style={{width:"100%"}}>
        <div style={{alignItems:"center",width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",marginBottom:30}}>
            <div className="bg--secondary" style={{padding:20,borderRadius:100,margin:"20px 0"}}>
            <SiGnuprivacyguard color="white" size={40}/>
            </div>
            <p>Login Account To Continue</p>
            </div>
    
      <TextField
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        value={state.email}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      <TextField
        label="Password"
        type={showPassword ? "text" : "password"}
          name="password"
        variant="outlined"
        value={state.password}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <MdOutlineRemoveRedEye /> : <FaRegEyeSlash />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && <p style={{color:"red"}}>{error}</p>}
      <div style={{display:"flex",justifyContent:"space-between",alignItems:'center',flexWrap:"wrap"}}>
      <p className="cursor--pointer" style={{textAlign:""}}>Forget password? <span className="text-is--primary "><NavLink className="text-is--primary" to="/forgot-password">Reset</NavLink></span></p>
      <p className="cursor--pointer" style={{textAlign:""}}>Don't Have An Account? <span className="text-is--primary "><NavLink className="text-is--primary" to="/signup">Signup</NavLink></span></p>
      
      </div>
     <button className="nyd-button nyd-button--primary" onClick={HandleSubmit}>{loading ? "Processing..." : "Submit"}</button>
      </div>
      </div>
      </section>
    </>
  );
};

export default Login;
