import React, { useState } from 'react';
import { TextField, Button, Typography, Input, Box, Select, MenuItem } from '@mui/material';
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from '../pages/dashboard';
import Agent from './contactAgent';

const NAFDACForm = () => {
  const [productName, setProductName] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [companyCertificate, setCompanyCertificate] = useState(null);
  const [productDossiers, setProductDossiers] = useState(null);
  const [productSamples, setProductSamples] = useState(null);
  const [brandCertificate, setBrandCertificate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFilePick = (stateSetter) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result; // Get the full data URL
          stateSetter(base64String); // Set the full data URL
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  const handleSubmit = () => {
    // Perform validation and handle form submission logic here
    console.log({ productName, manufacturerName, companyCertificate, productDossiers, productSamples, brandCertificate })
    if (!productName || !manufacturerName || !selectedCategory) {
      setErrorMessage('Product Name, Manufacturer Name, and Category are required');
      return;
    }

    if (isCompany && (!companyCertificate || !productDossiers || !productSamples || !brandCertificate)) {
      setErrorMessage('All attachment fields are required for company registration');
      return;
    }

    setErrorMessage('');

    // Additional validation logic can be added as needed

    // Handle the form submission, for example, send data to the server
  };
  const getFileName = (file) => (file ? file.name : '');

  return (
    <div className="MainDash" style={{ padding:"0 20px"}}>

      <Agent Message={"Requirement for NAFDAC registration"}/>
    {/* <p style={{color:"#08384e",fontSize:20,marginTop:"4rem",width:"100%",textAlign:"left"}}>{Salutation()},<span style={{color:"black"}}> Sixtus !</span> </p>
    <p>{"Complete requirement for NAFDAC registration below"}</p> */}
    
    {/* <Box sx={{ padding: '0px' }}> */}
      {/* <div style={{ display: "flex", alignItems: "center", margin: " " }}> */}
        {/* <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' /> */}
        {/* <p style={{ padding: 0, margin: 0, color: "gray" }}> 1. The process takes about 3 months if all the requirements are met as at when due.</p> */}
      {/* </div> */}
      {/* <p style={{ paddingTop: "6px", marginLeft: "20px", color: "gray" }} > 1. Non-refundable fee for product registration payable if your documents meet the requirements and confirmed as satisfied in line with their guidelines.</p>
      <p style={{ paddingTop: "6px", marginLeft: "20px", color: "gray" }} > 2. The application needs to be on a single product. If you want to register more than one product, you will have to register each separately.</p> */}

      {/* <TextField
        select
        label="Select Category"
        value={selectedCategory}
        onChange={(event) => setSelectedCategory(event.target.value)}
        fullWidth
        margin="normal"
      >
        <option value="">Select category</option>
        <option value="food">Food</option>
        <option value="drugs">Drugs</option>
      </TextField> */}

      {/* <TextField
        label="Product Name"
        value={productName}
        onChange={(event) => setProductName(event.target.value)}
        fullWidth
        margin="normal"
      /> */}
      {/* <TextField
        label="Manufacturer Name"
        value={manufacturerName}
        onChange={(event) => setManufacturerName(event.target.value)}
        fullWidth
        margin="normal"
      /> */}
{/* <label>Is the registration for a company?</label> */}
      {/* <Select
        select
        placeholder="Is the registration for a company?"
        value={isCompany}
        onChange={(event) => setIsCompany(event.target.value === 'true')}
        fullWidth
        margin="normal"
      >
        <MenuItem value="false">No</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
      </Select> */}

      {/* {isCompany && (
        <>
          <Typography variant="h6" gutterBottom style={{marginTop:"40px"}}>
            Attachments for Company Registration
          </Typography>

          <Input
            type="file"
            onChange={handleFilePick(setCompanyCertificate)}
            accept="application/pdf"
            // sx={{ display: 'none' }}
            id="companyCertificateInput"
          />
          <p htmlFor="companyCertificateInput">
            {companyCertificate ? 'Company Certificate (CAC) Attached' : 'Attach Company Certificate (CAC)'}
          </p>

          <Input
            type="file"
            onChange={handleFilePick(setProductDossiers)}
            accept="application/pdf"
            // sx={{ display: 'none' }}
            id="productDossiersInput"
          />
          <p htmlFor="productDossiersInput">
            {productDossiers ? " Product Dossiers Attached" : 'Attach Product Dossiers'}
          </p>

          <Input
            type="file"
            onChange={handleFilePick(setProductSamples)}
            accept="application/pdf"
            // sx={{ display: 'none' }}
            id="productSamplesInput"
          />
          <p htmlFor="productSamplesInput">
            {productSamples ? "Product Samples Attached" : 'Attach Product Samples'}
          </p>

          <Input
            type="file"
            onChange={handleFilePick(setBrandCertificate)}
            accept="application/pdf"
            // sx={{ display: 'none' }}
            id="brandCertificateInput"
          />
          <p htmlFor="brandCertificateInput">

            {brandCertificate ? "Brand Certificate (Trademark) Attached" : 'Attach Brand Certificate (Trademark)'}
          </p>
        </>
      )} */}

      {/* {errorMessage ? <Typography variant="body2" color="error">{errorMessage}</Typography> : null} */}

      {/* <Button variant="contained" onClick={handleSubmit} style={{ margin: '30px 0', background: 'var(--nyd-secondary)' }}>
        Submit
      </Button> */}
    {/* </Box> */}
    </div>
  );
};

export default NAFDACForm;
