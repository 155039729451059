import React, { useState } from 'react';
import { Modal,TextField, Button, Typography, Input, Box } from '@mui/material';
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from '../pages/dashboard';
import { useGlobalContext } from '../utills/store';
import axiosInstance from '../utills/axios';
import UsePaystack from '../utills/paystack';
import { toast } from 'react-toastify';
import Admin from '../pages/dashboard/admin';

const initialFormData = {
  nameReservation1: '',
  nameReservation2: '',
  directors: [],
  shareholders: [],
  shareCapital: '',
  registeredAddress: '',
  email: '',
  phone: '',
  shareDistribution: '',
  businessObjects: '',
  contactEmail:'',
  contactPhone:''
};


const CompanyRegistrationForm = () => {
  const { user } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState('');
  const [contactErrormessage, setContactErrormessage] = useState('');

  const handleFilePick = (stateSetter) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result; // Get the full data URL
          stateSetter(base64String); // Set the full data URL
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  // Function to format number with commas
  const formatNumberWithCommas = (number) => {
    if (!number) return '';
    return parseFloat(number).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2, // Adjust based on whether you want decimals
    });
  };

  const handleDirectorChange = (index, field, value) => {
    const updatedDirectors = [...formData.directors];
    updatedDirectors[index][field] = value;
    setFormData({ ...formData, directors: updatedDirectors });
  };

  const handleShareholderChange = (index, field, value) => {
    const updatedShareholders = [...formData.shareholders];
    updatedShareholders[index][field] = value;
    setFormData({ ...formData, shareholders: updatedShareholders });
  };

  const handleAddDirector = () => {
    setFormData({
      ...formData,
      directors: [
        ...formData.directors,
        { name: '', sex: '', address: '', phone: '', email: '', dob: '', signature: null, occupation: '', idDocument: null }
      ]
    });
  };

  const handleAddShareholder = () => {
    setFormData({
      ...formData,
      shareholders: [
        ...formData.shareholders,
        { name: '', sex: '', address: '', phone: '', email: '', dob: '', signature: null, occupation: '', idDocument: null }
      ]
    });
  };

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formData).some(
      key => key !== 'contactEmail' && key !== 'contactPhone' && !formData[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }
   
    setOpenModal(true);
  };
  const handlePay = () => {
    if (formData.contactEmail === "" || formData.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    setContactErrormessage("");
    setLoading(true);
    return true;
  };

  const handleRemoveDirector = (index) => {
    const updatedDirectors = [...formData.directors];
    updatedDirectors.splice(index, 1);
    setFormData({ ...formData, directors: updatedDirectors });
  };

  const handleRemoveShareholder = (index) => {
    const updatedShareholders = [...formData.shareholders];
    updatedShareholders.splice(index, 1);
    setFormData({ ...formData, shareholders: updatedShareholders });
  };

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = ({reference}) => {
    setContactErrormessage("")
    setLoading(true)
    
    axiosInstance.post("/register-company",{...formData,transactionRef:reference},{
        headers:{
          "Content-Type":"application/json"
        }
      }).then((res)=>{
        setLoading(false)
        toast(res.data);
        setOpenModal(false);
        setFormData(initialFormData);

      }).catch(err=>{
        setLoading(false)
        console.log(err)
        toast.error(err.message)
      })
  };
  

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
      {user.role === "admin" ? (
        <Admin service={"Company Registration"} route={"register-company"} />
      ) :
    <>
      <p style={{ color: "#08384e", fontSize: 20, marginTop: "4rem", width: "100%", textAlign: "left" }}>
        {Salutation()},<span style={{ color: "black" }}> {user?.fullName?.split(" ")[0]} !</span>
      </p>
     
      <p><b>{"Requirement for Company Registration Form is below."}</b></p>
   
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Contact Information
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Phone"
              type="tel"
              name="contactPhone"
              variant="outlined"
              value={formData.contactPhone}
              onChange={handleInputChange('contactPhone')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <TextField
              label="Email"
              type="email"
              name="contactEmail"
              variant="outlined"
              value={formData.contactEmail}
              onChange={handleInputChange('contactEmail')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
         
            <UsePaystack onClose={()=>{console.log("closed"); setLoading(false);} } handlePay={handlePay} isLoading={loading} onSuccess={handleSubmit} email={formData.contactEmail} amount={90000}/>
            <p style={{marginTop:"20px"}}>Note: An agent will be assigned to you shortly.</p>
            { contactErrormessage && <p style={{color:"red"}}>{contactErrormessage}</p>}
          </div>
        </box>
      </Modal>
      <Box sx={{ padding: '0px' }}>
        <Typography variant="subtitle2" gutterBottom>
          Name Reservation
        </Typography>
        <TextField
          label="Preferred Name 1"
          value={formData.nameReservation1}
          onChange={handleInputChange('nameReservation1')}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Preferred Name 2"
          value={formData.nameReservation2}
          onChange={handleInputChange('nameReservation2')}
          fullWidth
          margin="normal"
        />

        <Typography variant="subtitle2" gutterBottom style={{ marginTop: 20 }}>
          Directors Information
        </Typography>
        {formData.directors.map((director, index) => (
          <div key={index} style={{ marginBottom: '16px' }}>
            <TextField
              label="Name"
              value={director.name}
              onChange={(event) => handleDirectorChange(index, 'name', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sex"
              value={director.sex}
              onChange={(event) => handleDirectorChange(index, 'sex', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Occupation"
              value={director.occupation}
              onChange={(event) => handleDirectorChange(index, 'occupation', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              value={director.address}
              onChange={(event) => handleDirectorChange(index, 'address', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              value={director.phone}
              onChange={(event) => handleDirectorChange(index, 'phone', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={director.email}
              onChange={(event) => handleDirectorChange(index, 'email', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date of Birth"
              onFocus={(event)=> event.target.type = 'date'  }
              onBlur={(event)=> event.target.type = 'text' }
              value={director.dob}
              onChange={(event) => handleDirectorChange(index, 'dob', event.target.value)}
              fullWidth
              margin="normal"
            />
            <p style={{ marginTop: 30 }}>Means of Identification:</p>
            <Input
              type="file"
              onChange={handleFilePick((file) => handleDirectorChange(index, 'idDocument', file))}
              fullWidth
              margin="normal"
            />
            <p style={{ marginTop: 30 }}>Directors Signature:</p>
            <Input
              type="file"
              fullWidth
              onChange={handleFilePick((file) => handleDirectorChange(index, 'signature', file))}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
              <p style={{ padding: 0, margin: 0, color: "gray" }}>  Signature should be signed on a plain white paper.</p>
            </div>
            <br />
             <div style={{ display: "flex", alignItems: "center" }}>
              {/* <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' /> */}
              <span>NOTE: </span>
              <p style={{ padding: 0, margin: 0, color: "gray" }}>  A director can be a shareholder but does not necessary have to be a shareholder</p>
            </div>
            <br />
            <br />
            <Button variant="outlined" onClick={() => handleRemoveDirector(index)} style={{ color: "var(--nyd-secondary)", borderColor: "var(--nyd-secondary)" }}>
              Remove Director
            </Button>
          </div>
        ))}
        <Button variant="contained" onClick={handleAddDirector} style={{ background: "var(--nyd-secondary)" }}>
          Add Director
        </Button>

        <Typography variant="subtitle2" gutterBottom style={{ marginTop: 40 }}>
          Shareholders Information
        </Typography>
        {formData.shareholders.map((shareholder, index) => (
          <div key={index} style={{ marginBottom: '16px' }}>
            <TextField
              label="Name"
              value={shareholder.name}
              onChange={(event) => handleShareholderChange(index, 'name', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sex"
              value={shareholder.sex}
              onChange={(event) => handleShareholderChange(index, 'sex', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Occupation"
              value={shareholder.occupation}
              onChange={(event) => handleShareholderChange(index, 'occupation', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              value={shareholder.address}
              onChange={(event) => handleShareholderChange(index, 'address', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              value={shareholder.phone}
              onChange={(event) => handleShareholderChange(index, 'phone', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={shareholder.email}
              onChange={(event) => handleShareholderChange(index, 'email', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date of Birth"
              onFocus={(event)=> event.target.type = 'date'  }
              onBlur={(event)=> event.target.type = 'text' }
              value={shareholder.dob}
              onChange={(event) => handleShareholderChange(index, 'dob', event.target.value)}
              fullWidth
              margin="normal"
            />
            <p>ShareHolders Signature:</p>
            <Input type="file" fullWidth onChange={handleFilePick((file) => handleShareholderChange(index, 'signature', file))} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
              <p style={{ padding: 0, margin: 0, color: "gray" }}>  Signature should be signed on a plain white paper.</p>
            </div>
            <p style={{ marginTop: 30 }}>Means of Identification:</p>
            <Input
              type="file"
              onChange={handleFilePick((file) => handleShareholderChange(index, 'idDocument', file))}
              fullWidth
            />
            <br />
            <br />
            <Button variant="outlined" onClick={() => handleRemoveShareholder(index)} style={{ color: "var(--nyd-secondary)", borderColor: "var(--nyd-secondary)" }}>
              Remove Shareholder
            </Button>
          </div>
        ))}
        <Button variant="contained" onClick={handleAddShareholder} style={{ background: "var(--nyd-secondary)", marginBottom: 40 }}>
          Add Shareholder
        </Button>

        <TextField
          label="Share Capital"
          value={formatNumberWithCommas(formData.shareCapital)} // Display formatted value
          onChange={handleInputChange('shareCapital')}
          fullWidth
          margin="normal"
          type="number"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} />
          <p style={{ padding: 0, margin: 0, color: "gray" }}>  Minimum share capital is {'\u20A6'}1,000,000.</p>
        </div>
        <p style={{ padding: 0, marginLeft: 15, color: "gray" }}> Registration charges increase as share capital increases.</p>

        <TextField
          label="Address of the company"
          value={formData.registeredAddress}
          onChange={handleInputChange('registeredAddress')}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Email"
          value={formData.email}
          onChange={handleInputChange('email')}
          fullWidth
          margin="normal"
          type='email'
        />
        <TextField
          label="Phone Number"
          type='tel'
          value={formData.phone}
          onChange={handleInputChange('phone')}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Percentage Share Distribution"
          value={formData.shareDistribution}
          onChange={handleInputChange('shareDistribution')}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Objects of the Company"
          value={formData.businessObjects}
          onChange={handleInputChange('businessObjects')}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
          <p style={{ padding: 0, margin: 0, color: "gray" }}>  What businesses it intends to engage in.</p>
        </div>
        <br/>
        <div style={{ display: "flex", alignItems: "center" }}>
              <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
              <p style={{ padding: 0, margin: 0, color: "gray" }}>  A minor cannot be a director but can be shareholder</p>
            </div>

        {errorMessage ? <Typography variant="body2" color="error">{errorMessage}</Typography> : null}

        <Button variant="contained" onClick={HandleModal} style={{ margin: '30px 0', background: "var(--nyd-secondary)" }}>
          Submit
        </Button>
      </Box>
      </>}
    </div>
  );
};

export default CompanyRegistrationForm;
