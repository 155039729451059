import react from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const ContactUs = () => {
  return (
    <section style={{ minHeight: "100vh", width: "100%", marginTop: 100 }}>
      <h2 style={{ textAlign: "center", marginTop: 150 }}>Contact Us</h2>

      <div className="contact-card-wrapper">
        <div className="contact-card">
          <h5>Address</h5>
          <br />
          <p>Lagos State, Nigeria.</p>
        </div>
        <div className="contact-card">
          <h5>Tel</h5>
          <br />
          <p>phone 1: 08100027470</p>
          <p>phone 2: 09037739551</p>
          <p><a style={{textDecoration:"none",color:"black"}} href= "mailto:support@easycomply.org">email 1: support@easycomply.org</a></p>
          <p><a style={{textDecoration:"none",color:"black"}} href= "mailto:contact@easycomply.org">email 2: contact@easycomply.org</a></p>
        </div>
        <div className="contact-card">
          <h5> Social media</h5>
          <br />
          <a
            href="https://www.facebook.com/easy-comply"
            style={{ color: "var(--nyd-secondary)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="social-icon" color="black" />
            <span>Facebook</span>
          </a>
          <br />
          <br />
          <a
            href="https://twitter.com/Easy_complyltd"
            style={{ color: "var(--nyd-secondary)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" color="black" />
            <span>Twitter</span>
          </a>
          <br />
          <br />
          <a
            href="https://www.instagram.com/easy_comply"
            style={{ color: "var(--nyd-secondary)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" color="black" />
            <span>Instagram</span>
          </a>
          <br />
          <br />
          <a
            href="https://www.linkedin.com/in/easycomply"
            style={{ color: "var(--nyd-secondary)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="social-icon" color="black" />
            <span>LinkedIn</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
