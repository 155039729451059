import React, { createContext, useReducer, useContext } from 'react';

// Define your initial state
const initialState = {
  activeRegistration: 'Dashboard', 
  activelink: '',
  isLoggedIn:false,
  user:{}
};

// Define your reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_link':
      localStorage.setItem("activeLink",action.payload)

      return {
        ...state,
        activeLink: action.payload,
      };
case 'STORE_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_ACTIVE_REGISTRATION':
      localStorage.setItem("activeLink",action.payload)
      return {
        ...state,
        activeRegistration: action.payload,
      };
      case 'SET_ISLOGGEDIN':
        localStorage.setItem("isLoggedIn",true)
        return {
          ...state,
          isLoggedIn: action.payload,
        }; 
        case 'LOGGEDOUT':
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userxx");
        localStorage.removeItem("tok_xx");
        return {
          ...state,
          isLoggedIn: false,
        };
    default:
      return state;
  }
};

// Create a context
const GlobalContext = createContext();

// Create a context provider
const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Define your custom actions
  const setActiveRegistration = (value) => {
    dispatch({ type: 'SET_ACTIVE_REGISTRATION', payload: value });
  };
   const setUser = (value) => {
    dispatch({ type: 'STORE_USER', payload: value });
  };
  const setActiveLink = (value) => {
    dispatch({ type: 'SET_ACTIVE_link', payload: value });
  };
   const setIsLoggedIn = (value) => {
    dispatch({ type: 'SET_ISLOGGEDIN', payload: true });
  }; 
   const logOut = (value) => {
    dispatch({ type: 'LOGGEDOUT', payload: false });
  };

  // Expose the state and actions through the context
   const contextValue = {
    setUser,
    user:state.user,
    activeRegistration: state.activeRegistration,
    setActiveRegistration,
    activeLink: state.activeLink,
    setActiveLink,
    setIsLoggedIn,
    isLoggedIn:state.isLoggedIn,
    logOut
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the context
const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

export { GlobalProvider, useGlobalContext };
