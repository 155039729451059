import React, { useState } from 'react';
import { Modal,TextField, Button, Typography, Input, Box } from '@mui/material';
import { Salutation } from '../pages/dashboard';
import axiosInstance from '../utills/axios';
import { toast } from 'react-toastify';
import UsePaystack from '../utills/paystack';
import Admin from '../pages/dashboard/admin';
import { useGlobalContext } from '../utills/store';


const initialState = {
  certificate: null,
  memart: null,
  statusReport: null,
  accountDetails: '',
  bvn: '',
  taxClearance: null,
  taxIdentificationNumber: '',
  directorBVN: '',
  directorNIN: '',
  directorDOB: '',
  companyEmail: '',
  directorEmail: '',
  pickupLocation: '',
  contactEmail:'',
  contactPhone:''
}
const ScumlRegistrationForm = () => {
  const [formState, setFormState] = useState(initialState);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactErrormessage, setContactErrormessage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useGlobalContext();

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formState).some(
      key => key !== 'contactEmail' && key !== 'contactPhone' && !formState[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }
   
    setOpenModal(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFilePick = (name) => (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setFormState(prevState => ({
        ...prevState,
        [name]: file
      }));
    } else {
      setErrorMessage('Please upload a PDF file.');
    }
  };

  const handlePay = () => {
    if (formState.contactEmail === "" || formState.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    setContactErrormessage("");
    setIsLoading(true);
    return true;
  };

  const handleSubmit = async ({reference}) => {
    // Check if any required field is missing
    const requiredFields = ['certificate', 'memart', 'statusReport', 'accountDetails', 'bvn', 'taxClearance', 'taxIdentificationNumber', 'directorBVN', 'directorNIN', 'directorDOB', 'companyEmail', 'directorEmail', 'pickupLocation'];
    if (requiredFields.some(field => !formState[field])) {
      setErrorMessage('All fields are required');
      return;
    }

    // Prepare form data for submission

    try {
      setIsLoading(true)
      const response = await axiosInstance.post('/scuml-registration', {...formState,transactionRef:reference}, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setIsLoading(false)
      toast.success(response.data.message);
      setFormState(initialState);
      setOpenModal(false);
    } catch (error) {
      setErrorMessage('Failed to submit registration. Please try again.');
      setIsLoading(false)
    }
  };

  return (
    <div className="MainDash" style={{ padding:"0 20px"}}>
       {user.role === "admin" ? (
        <Admin service={"Scuml Registration"} route={"scuml-registration"} />
      ) : (
        <>
      <p style={{color:"#08384e",fontSize:20,marginTop:"4rem",width:"100%",textAlign:"left"}}>{Salutation()},<span style={{color:"black"}}> {(user?.fullName?.split(" ")[0])} !</span></p>
      <p><b>{"Complete Scuml Registration Form below."}</b></p>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Contact Information
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Phone"
              type="tel"
              name="contactPhone"
              variant="outlined"
              value={formState.contactPhone}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <TextField
              label="Email"
              type="email"
              name="contactEmail"
              variant="outlined"
              value={formState.contactEmail}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
             {contactErrormessage ? (
          <Typography variant="body2" color="error">
            {contactErrormessage}
          </Typography>
        ) : null}
                    <UsePaystack onClose={()=>{console.log("closed"); setIsLoading(false);} } handlePay={handlePay} isLoading={isLoading} onSuccess={handleSubmit} email={formState.contactEmail} amount={100000}/>

           
           
            <p style={{marginTop:"20px"}}>Note: An agent will be assigned to you shortly.</p>
          </div>
        </Box>
      </Modal>
      <Box sx={{ padding: '0px', marginTop:"20px" }}>
        <p>CAC Documents:</p>
        <p>Certificate</p>
        <Input fullWidth type="file" accept="application/pdf" onChange={handleFilePick('certificate')} style={{marginBottom:"20px"}} />
        <p>Memart</p>
        <Input fullWidth type="file" accept="application/pdf" onChange={handleFilePick('memart')} style={{marginBottom:"20px"}}/>
        <p>Status report</p>
        <Input fullWidth type="file" accept="application/pdf" onChange={handleFilePick('statusReport')} style={{marginBottom:"20px"}}/>

        <TextField
          label="Account details of the Company/Business/Incoporated Trustee(IT)"
          name="accountDetails"
          value={formState.accountDetails}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="BVN"
          name="bvn"
          value={formState.bvn}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <p style={{marginTop:"25px"}}>Tax clearance certificate or any evidence of tax registration</p>
        <Input fullWidth type="file" accept="application/pdf" onChange={handleFilePick('taxClearance')} />

        <TextField
          label="Tax identification number"
          name="taxIdentificationNumber"
          value={formState.taxIdentificationNumber}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          style={{marginTop:"25px"}}
        />
        <TextField
          label="BVN of at least one of the Directors/Proprietor or Trustee"
          name="directorBVN"
          value={formState.directorBVN}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="NIN of at least one of the Directors/Proprietor or Trustee"
          name="directorNIN"
          value={formState.directorNIN}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Date of birth of the Director/Proprietor or Trustee"
          onFocus={(event)=> event.target.type = 'date'  }
          onBlur={(event)=> event.target.type = 'text' }
          name="directorDOB"
          value={formState.directorDOB}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Active email address of the Company/Business/Incoporated Trustee(IT)"
          name="companyEmail"
          value={formState.companyEmail}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Active email address of the Director/Proprietor or Trustee"
          name="directorEmail"
          value={formState.directorEmail}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Location for pickup of certificate"
          name="pickupLocation"
          value={formState.pickupLocation}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />

        {errorMessage ? <Typography variant="body2" color="error">{errorMessage}</Typography> : null}
        <p style={{marginTop:"30px"}}><b>NOTE:</b> please note certain companies, businesses or incorporated trustees require an additional professional certificate, we will revert to you upon review of your CAC documents whether you require a professional certificate or not.</p>
        <Button variant="contained" onClick={HandleModal} style={{ margin: '30px 0', background:"var(--nyd-secondary)" }}>
          Submit
        </Button>
      </Box>
      </>)}
    </div>
  );
};

export default ScumlRegistrationForm;
