import React, { useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  Input,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from "../pages/dashboard";
import { toast } from "react-toastify";
import axiosInstance from "../utills/axios";
import { useGlobalContext } from "../utills/store";
import Admin from "../pages/dashboard/admin";
import UsePaystack from "../utills/paystack";

const initialState = {
  logoFile: null,
  logoName: "",
  selectedClasses: "",
  cacCertificationFile: null,
  memartFile: null,
  statusReportFile: null,
  contactEmail: "",
  contactPhone: "",
};

const TrademarkRegistrationForm = () => {
  const [formData, setFormData] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactErrormessage, setContactErrormessage] = useState("");
  const { user } = useGlobalContext();

  const serviceDetailsMap = [
    "1. Chemicals used in industry, science and photography, as well as in agriculture, horticulture and forestry; unprocessed artificial resins; unprocessed plastics; manures; fire extinguishing compositions; tempering and soldering preparations; chemical substances for preserving foodstuffs; tanning substances; adhesives used in industry.",
    "2. Paints, varnishes, lacquers; preservatives against rust and against deterioration of wood; colorants; mordants; raw natural resins; metals in foil and powder form for painters, decorators, printers and artists.",
    "3. Bleaching preparations and other substances for laundry use; cleaning, polishing, scouring and abrasive preparations; soaps; perfumery, essential oils, cosmetics, hair lotions; dentifrices.",
    "4. Industrial oils and greases; lubricants; dust absorbing, wetting and binding compositions; fuels (including motor spirit) and illuminants; candles and wicks for lighting.",
    "5. Pharmaceutical and veterinary preparations; sanitary preparations for medical purposes; dietetic substances adapted for medical use, food for babies; plasters, materials for dressings; material for stopping teeth, dental wax; disinfectants; preparations for destroying vermin; fungicides, herbicides.",
    "6. Common metals and their alloys; metal building materials; transportable buildings of metal; materials of metal for railway tracks; nonelectric cables and wires of common metal; ironmongery, small items of metal hardware; pipes and tubes of metal; safes; goods of common metal not included in other classes; ores.",
    "7. Machines and machine tools; motors and engines (except for land vehicles); machine coupling and transmission components (except for land vehicles); agricultural implements other than hand-operated; incubators for eggs.",
    "8. Hand tools and implements (hand-operated); cutlery; side arms; razors.",
    "9. Scientific, nautical, surveying, photographic, cinematographic, optical, weighing, measuring, signalling, checking (supervision), life-saving and teaching apparatus and instruments; apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling electricity; apparatus for recording, transmission or reproduction of sound or images; magnetic data carriers, recording discs; automatic vending machines and mechanisms for coin operated apparatus; cash registers, calculating machines, data processing equipment and computers; fire extinguishing apparatus.",
    "10. Surgical, medical, dental, and veterinary apparatus and instruments, artificial limbs, eyes, and teeth; orthopedic articles; suture materials.",
    "11. Apparatus for lighting, heating, steam generating, cooking, refrigerating, drying, ventilating, water supply, and sanitary purposes.",
    "12. Vehicles; apparatus for locomotion by land, air, or water.",
    "13. Firearms; ammunition and projectiles; explosives; fireworks.",
    "14. Precious metals and their alloys and goods in precious metals or coated therewith, not included in other classes; jewelry, precious stones; horological and chronometric instruments.",
    "15. Musical instruments.",
    "16. Paper, cardboard and goods made from these materials, not included in other classes; printed matter; bookbinding material; photographs; stationery; adhesives for stationery or household purposes; artists' materials; paint brushes; typewriters and office requisites (except furniture); instructional and teaching material (except apparatus); plastic materials for packaging (not included in other classes); printers' type; printing blocks.",
    "17. Rubber, gutta-percha, gum, asbestos, mica and goods made from these materials and not included in other classes; plastics in extruded form for use in manufacture; packing, stopping and insulating materials; flexible pipes, not of metal.",
    "18. Leather and imitations of leather, and goods made of these materials and not included in other classes; animal skins, hides; trunks and travelling bags; umbrellas, parasols and walking sticks; whips, harness and saddlery.",
    "19. Building materials (non-metallic); nonmetallic rigid pipes for building; asphalt, pitch and bitumen; nonmetallic transportable buildings; monuments, not of metal.",
    "20. Furniture, mirrors, picture frames; goods (not included in other classes) of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother-of-pearl, meerschaum and substitutes for all these materials, or of plastics.",
    "21. Household or kitchen utensils and containers; combs and sponges; brushes (except paint brushes); brush-making materials; articles for cleaning purposes; steel-wool; unworked or semi-worked glass (except glass used in building); glassware, porcelain and earthenware not included in other classes.",
    "22. Ropes, string, nets, tents, awnings, tarpaulins, sails, sacks and bags (not included in other classes); padding and stuffing materials (except of rubber or plastics); raw fibrous textile materials.",
    "23. Yarns and threads, for textile use.",
    "24. Textiles and textile goods, not included in other classes; beds and table covers.",
    "25. Clothing, footwear, headgear.",
    "26. Lace and embroidery, ribbons and braid; buttons, hooks and eyes, pins and needles; artificial flowers.",
    "27. Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; wall hangings (non-textile).",
    "28. Games and playthings; gymnastic and sporting articles not included in other classes; decorations for Christmas trees.",
    "29. Meat, fish, poultry and game; meat extracts; preserved, frozen, dried and cooked fruits and vegetables; jellies, jams, compotes; eggs, milk and milk products; edible oils and fats.",
    "30. Coffee, tea, cocoa, sugar, rice, tapioca, sago, artificial coffee; flour and preparations made from cereals, bread, pastry and confectionery, ices; honey, treacle; yeast, baking powder; salt, mustard; vinegar, sauces (condiments); spices; ice.",
    "31. Agricultural, horticultural and forestry products and grains not included in other classes; live animals; fresh fruits and vegetables; seeds, natural plants and flowers; foodstuffs for animals; malt.",
    "32. Beers; mineral and aerated waters and other nonalcoholic drinks; fruit drinks and fruit juices; syrups and other preparations for making beverages.",
    "33. Alcoholic beverages (except beers).",
    "34. Tobacco; smokers' articles; matches.",
    "35. Advertising; business management; business administration; office functions.",
    "36. Insurance; financial affairs; monetary affairs; real estate affairs.",
    "37. Building construction; repair; installation services.",
    "38. Telecommunications.",
    "39. Transport; packaging and storage of goods; travel arrangement.",
    "40. Treatment of materials.",
    "41. Education; providing of training; entertainment; sporting and cultural activities.",
    "42. Scientific and technological services and research and design relating thereto; industrial analysis and research services; design and development of computer hardware and software.",
    "43. Services for providing food and drink; temporary accommodations.",
    "44. Medical services; veterinary services; hygienic and beauty care for human beings or animals; agriculture, horticulture and forestry services.",
    "45. Legal services; security services for the protection of property and individuals; personal and social services rendered by others to meet the needs of individuals.",
  ];

  const handlePay = () => {
    if (formData.contactEmail === "" || formData.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    setContactErrormessage("");
    setIsLoading(true);
    return true;
  };

  const handleServiceChange = (event) => {
    setFormData({
      ...formData,
      selectedClasses: event.target.value,
    });
  };

  const handleFilePick = (stateKey, fileType) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        setFormData({ ...formData, [stateKey]: file });
      } else if (
        fileType === "image" &&
        (file.type === "image/jpeg" || file.type === "image/png")
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result; // Get the full data URL
          setFormData({ ...formData, [stateKey]: base64String });
          // stateSetter(base64String); // Set the full data URL
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        setErrorMessage(
          fileType === "pdf"
            ? "Only PDF files are allowed"
            : "Only image files (JPEG, PNG) are allowed"
        );
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formData).some(
      (key) =>
        key !== "contactEmail" && key !== "contactPhone" && !formData[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }

    setOpenModal(true);
  };

  const handleSubmit = async () => {
    // Perform validation and handle form submission logic here

    if (formData.contactEmail === "" || formData.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    try {
      
      const response = await axiosInstance.post("/trademark", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // Handle successful submission
        toast.success("Trademark filed successfully");
        setFormData(initialState);
      } else {
        toast.error("Submission failed");

        setErrorMessage("Submission failed");
      }
    } catch (error) {
      setErrorMessage("Error submitting the form");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
      {user.role === "admin" ? (
        <Admin service={"Trade mark"} route={"trademark"} />
      ) : (
        <>
          <p
            style={{
              color: "#08384e",
              fontSize: 20,
              marginTop: "4rem",
              width: "100%",
              textAlign: "left",
            }}
          >
            {Salutation()},
            <span style={{ color: "black" }}>
              
              <span style={{ color: "black" }}>
                {" "}
                {user?.fullName?.split(" ")[0]}!
              </span>
            </span>
          </p>
          <p><b>{"Complete Trade Mark Registration Form below."}</b></p>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box className="contact_box">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                style={{ textAlign: "center", margin: "30px 0" }}
              >
                Contact Information
              </Typography>
              <div style={{ width: "100%" }}>
                <TextField
                  label="Phone"
                  type="tel"
                  name="contactPhone"
                  variant="outlined"
                  value={formData.contactPhone}
                  onChange={handleInputChange}
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "GrayText" },
                  }}
                />
                <TextField
                  label="Email"
                  type="email"
                  name="contactEmail"
                  variant="outlined"
                  value={formData.contactEmail}
                  onChange={handleInputChange}
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "GrayText" },
                  }}
                />
                {contactErrormessage ? (
                  <Typography variant="body2" color="error">
                    {contactErrormessage}
                  </Typography>
                ) : null}
                <UsePaystack onClose={() => { setIsLoading(false); }} handlePay={handlePay} isLoading={isLoading} onSuccess={handleSubmit} email={formData.contactEmail} amount={185000} />

                {/* <Button
                  variant="contained"
                  onClick={HandleModal}
                  disabled={isLoading}
                  style={{
                    marginTop: "16px",
                    background: "var(--nyd-secondary)",
                  }}
                >
                  {isLoading ? "Please wait..." : "Proceed"}
                </Button> */}

                <p style={{ marginTop: "20px" }}>
                  Note: An agent will be assigned to you shortly.
                </p>
              </div>
            </Box>
          </Modal>
          <Box sx={{ padding: "0px" }}>
            <p style={{ marginTop: "30px" }}>
              Name And Logo You Want to TradeMark:
            </p>
            <TextField
              style={{ marginTop: "30px" }}
              label="Name"
              name="logoName"
              value={formData.logoName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <p style={{ margin: "30px 0" }}>Logo:</p>
            <Input
              fullWidth
              type="file"
              inputProps={{ accept: "image/jpeg, image/png" }}
              onChange={handleFilePick("logoFile", "image")}
            />

            <InputLabel style={{ marginTop: "30px" }} htmlFor="name-multiple">
              Select Class(es)
            </InputLabel>

            <Select
              value={formData.selectedClasses}
              style={{ overflowY: "auto !important" }}
              onChange={handleServiceChange}
              fullWidth
              margin="normal"
            >
              {serviceDetailsMap.map((service) => (
                <MenuItem
                  key={service}
                  value={service}
                  style={{
                    margin: "20px 0",
                    whiteSpace: "unset",
                    overflowWrap: "break-word",
                  }}
                >
                  {service}
                </MenuItem>
              ))}
            </Select>

            <p style={{ marginTop: 50 }}>CAC Certificate (PDF):</p>
            <Input
              type="file"
              fullWidth
              onChange={handleFilePick("cacCertificationFile", "pdf")}
              inputProps={{ accept: "application/pdf" }} // Restrict file picker to PDF files
            />

            <p style={{ marginTop: 20 }}>Memart (PDF):</p>
            <Input
              type="file"
              fullWidth
              onChange={handleFilePick("memartFile", "pdf")}
              inputProps={{ accept: "application/pdf" }} // Restrict file picker to PDF files
            />

            <p style={{ marginTop: 20 }}>Status Report (PDF):</p>
            <Input
              type="file"
              fullWidth
              onChange={handleFilePick("statusReportFile", "pdf")}
              inputProps={{ accept: "application/pdf" }} // Restrict file picker to PDF files
            />

            {errorMessage ? (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            ) : null}
            <br />
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaInfoCircle
                size={14}
                style={{ marginRight: 4, color: "gray" }}
                title="What businesses it intends to engage in."
              />
              <p style={{ padding: 0, margin: 0, color: "gray" }}>
                {" "}
                Acceptance is usually done within two to three weeks or less,
                publication is twice in a year and Certificate is once in a
                year.
              </p>
            </div>

            <Button
              variant="contained"
              onClick={HandleModal}
              style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default TrademarkRegistrationForm;
