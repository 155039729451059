import React, { useEffect,useState } from "react";
import "./mainDash.css"
import { useGlobalContext } from "../../utills/store";
import ResponsiveTable from "../../components/tabe";
import axiosInstance from "../../utills/axios";
import Spinner from "../../utills/preloader";


export const Salutation = () => {
  const now = new Date();
  const currentHour = now.getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return "Good morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};
function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}-${month}-${year}`;
}

const HomeDash = ()=>{
  const columns = [
    {
        name: 'N/O',
        selector: (row,index) => index + 1,
    },{
        name: 'Contact Email',
        selector: row => row.contactEmail,
     
    },
    {
        name: 'Contact Phone',
        selector: row => row.contactPhone,
       
    }, 
      {
        name: 'Transaction Ref',
        selector: row => row.transactionRef,
      
    },{
        name: 'Service Name',
        selector: row => row.serviceName,
      
    },{
        name: 'Creadted On',
        selector: row => formatDate(row.createdAt),
       
    },{
        name: 'Request Details',
        selector: row => formatDate(row.servicedetails),
       
    },
];
  const Agentcolumns = [
    {
        name: 'N/O',
        selector: (row,index) => index + 1,
    },{
        name: 'Full Name',
        selector: row => row.fullName,
     
    },
    {
        name: 'Contact Phone',
        selector: row => row.phone,
       
    },   {
        name: 'Contact Email',
        selector: row => row.email,
       
    }, 
    {
        name: 'Created On',
        selector: row => formatDate(row.createdAt),
       
    }
];
    const { activeRegistration, setActiveRegistration,user } = useGlobalContext();
    const [ data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (user && user.role) {
      setIsUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    if (isUserLoaded) {
      console.log(user, "ser");
      if (user.role === "user") {
        GetRequest().then();
      } else {
        GetAgents().then();
      }
    }
  }, [isUserLoaded, user]);

      const GetRequest = async()=>{
        let response = await axiosInstance.get("/requests")
        if(response.status === 200){
            console.log(response.data);
            setData(response.data)
            setLoading(false)
        }
      }
      const GetAgents = async()=>{
        let response = await axiosInstance.get("/agents")
        if(response.status === 200){
            console.log(response.data);
            setData(response.data)
            setLoading(false)
        }
      }

      // useEffect(()=>{
      //   let active = localStorage.getItem("activeLink")
      // active !== null ? setActiveRegistration(active) : setActiveRegistration("Dashboard")
    
      // },[])

      // useEffect(()=>{
      //   console.log(user,"ser")
      //   user.role === "user" ?  GetRequest().then() :GetAgents().then() 
      // },[])

          return(
            <div className="MainDash" style={{ padding:"0 20px"}}>
                <p style={{color:"#08384e",fontSize:20,marginTop:"4rem",width:"100%",textAlign:"left"}}>{Salutation()},<span style={{color:"black"}}> {(user?.fullName?.split(" ")[0])} !</span> </p>
               { user.role === "admin" ? <p>Registered Users ({data.length})</p> :<p>Registration History</p>}
               {loading ? <Spinner/> : <section>
               {user.role === "admin" ?  <ResponsiveTable data={data} columns={Agentcolumns}/> : <ResponsiveTable data={data} columns={columns}/>}
                </section>}
            </div>
        )
      


}

export default HomeDash