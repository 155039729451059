import React, { useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Input,
  Box,
} from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from "../pages/dashboard";
import { useGlobalContext } from "../utills/store";
import axios from "axios";
import { toast } from "react-toastify";
import UsePaystack from "../utills/paystack";
import axiosInstance from "../utills/axios";
import Admin from "../pages/dashboard/admin";

const BusinessNameRegistrationForm = () => {
  const { user } = useGlobalContext();
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactErrormessage, setContactErrormessage] = useState("");
  const [loading, setLoading] = useState(false);
  const initialFormState = {
    proposedName1: "",
    proposedName2: "",
    natureOfBusiness: "",
    email: "",
    phoneNumber: "",
    state: "",
    lga: "",
    cityTownVillage: "",
    houseNumberBuildingName: "",
    streetName: "",
    proprietorSurname: "",
    proprietorFirstName: "",
    proprietorOtherName: "",
    dob: "",
    gender: "",
    nationality: "",
    contactPhone: "",
    contactEmail: "",
    occupationDescription: "",
    country: "",
    proprietorState: "",
    proprietorLga: "",
    proprietorCityTownVillage: "",
    proprietorHouseNumberBuildingName: "",
    proprietorStreetName: "",
    idCardType: "",
    idCardNumber: "",
    idCardCopy: null,
    proprietorPassport: null,
    proprietorSignature: null,
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleFilePick = (stateSetter) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setFormState({ ...formState, [stateSetter]: base64String });
        };
        reader.readAsDataURL(file);
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formState).some(
      key => key !== 'contactEmail' && key !== 'contactPhone' && !formState[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }
   
    setOpenModal(true);
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type !== 'file') {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handlePay = () => {
    if (formState.contactEmail === "" || formState.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    setContactErrormessage("");
    setLoading(true);
    return true;
  };

  const handleSubmit = ({ reference }) => {
    setContactErrormessage("");
    setLoading(true);
    
    axiosInstance.post("/business_name", { ...formState, transactionRef: reference }, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((res) => {
      setLoading(false);
      toast(res.data);
      setOpenModal(false);
      setFormState(initialFormState);
    }).catch(err => {
      setLoading(false);
      console.log(err);
      toast.error(err.message);
    });
  };

  return (
    <>
      {user.role === "admin" ? <Admin service={"Business Name"} route={"business-name"} /> :
        <div className="MainDash" style={{ padding: "0 20px" }}>
          <p
            style={{
              color: "#08384e",
              fontSize: 20,
              marginTop: "4rem",
              width: "100%",
              textAlign: "left",
            }}
          >
            {Salutation()},
            <span style={{ color: "black" }}> {user?.fullName?.split(" ")[0]}!</span>{" "}
          </p>
          <p><b>{"Complete Requirement for Business Name Registration below."}</b></p>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <box className="contact_box">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                style={{ textAlign: "center", margin: "30px 0" }}
              >
                Contact Information
              </Typography>
              <div style={{ width: "100%" }}>
                <TextField
                  label="Phone number"
                  type="tel"
                  name="contactPhone"
                  variant="outlined"
                  value={formState.contactPhone}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
                />
                <TextField
                  label="Email"
                  type="email"
                  name="contactEmail"
                  variant="outlined"
                  value={formState.contactEmail}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
                />
                <UsePaystack onClose={() => { console.log("closed"); setLoading(false); }} handlePay={handlePay} isLoading={loading} onSuccess={handleSubmit} email={formState.contactEmail} amount={30000} />
                <p style={{ marginTop: "20px" }}>Note: An agent will be assigned to you shortly.</p>
                {contactErrormessage && <p style={{ color: "red" }}>{contactErrormessage}</p>}
              </div>
            </box>
          </Modal>

          <Box sx={{ padding: "0px" }}>
            <Typography variant="subtitle2" gutterBottom>
              Proposed Names:
            </Typography>
            <TextField
              label="Preferred Name 1"
              value={formState.proposedName1}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="proposedName1"
            />
            <TextField
              label="Preferred Name 2"
              value={formState.proposedName2}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="proposedName2"
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Nature of Business
            </Typography>
            <TextField
              label="Nature of Business"
              value={formState.natureOfBusiness}
              onChange={handleChange}
              fullWidth
              name="natureOfBusiness"
              multiline
              rows={4}
              margin="normal"
            />
            
            <TextField
              label="Email"
              value={formState.email}
              onChange={handleChange}
              fullWidth
              name="email"
              margin="normal"
            />
            <TextField
              label="Phone Number"
              value={formState.phoneNumber}
              onChange={handleChange}
              fullWidth
              name="phoneNumber"
              margin="normal"
            />
        
            <TextField
              label="State"
              value={formState.state}
              onChange={handleChange}
              fullWidth
              name="state"
              margin="normal"
            />
            <TextField
              label="LGA"
              value={formState.lga}
              onChange={handleChange}
              fullWidth
              name="lga"
              margin="normal"
            />
            <TextField
              label="City/Town/Village"
              value={formState.cityTownVillage}
              onChange={handleChange}
              fullWidth
              name="cityTownVillage"
              margin="normal"
            />
            <TextField
              label="House Number/Building Name"
              value={formState.houseNumberBuildingName}
              onChange={handleChange}
              fullWidth
              name="houseNumberBuildingName"
              margin="normal"
            />
            <TextField
              label="Street Name"
              value={formState.streetName}
              onChange={handleChange}
              fullWidth
              name="streetName"
              margin="normal"
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Personal Details of Proprietor
            </Typography>
            <TextField
              label="Surname"
              value={formState.proprietorSurname}
              onChange={handleChange}
              fullWidth
              name="proprietorSurname"
              margin="normal"
            />
            <TextField
              label="First Name"
              value={formState.proprietorFirstName}
              onChange={handleChange}
              fullWidth
              name="proprietorFirstName"
              margin="normal"
            />
            <TextField
              label="Other Name"
              value={formState.proprietorOtherName}
              onChange={handleChange}
              fullWidth
              name="proprietorOtherName"
              margin="normal"
            />
            <TextField
              label="Date of Birth"
              onFocus={(event)=> event.target.type = 'date'  }
              onBlur={(event)=>     event.target.type = 'text' }
              value={formState.dob}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="dob"
            />
            <TextField
              label="Gender"
              value={formState.gender}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="gender"
            />
            <TextField
              label="Nationality"
              value={formState.nationality}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="nationality"
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Contact Details of Proprietor
            </Typography>
            <TextField
              label="Phone Numbers"
              value={formState.contactPhone}
              onChange={handleChange}
              fullWidth
              name="contactPhone"
              margin="normal"
            />
            <TextField
              label="Email"
              value={formState.contactEmail}
              onChange={handleChange}
              fullWidth
              name="contactEmail"
              margin="normal"
            />
            <TextField
              label="Occupation/Description"
              value={formState.occupationDescription}
              onChange={handleChange}
              fullWidth
              name="occupationDescription"
              margin="normal"
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Address of Proprietor
            </Typography>
            <TextField
              label="Country"
              value={formState.country}
              onChange={handleChange}
              fullWidth
              name="country"
              margin="normal"
            />
            <TextField
              label="State"
              value={formState.proprietorState}
              onChange={handleChange}
              fullWidth
              name="proprietorState"
              margin="normal"
            />
            <TextField
              label="LGA"
              value={formState.proprietorLga}
              onChange={handleChange}
              fullWidth
              name="proprietorLga"
              margin="normal"
            />
            <TextField
              label="City/Town/Village"
              value={formState.proprietorCityTownVillage}
              onChange={handleChange}
              fullWidth
              name="proprietorCityTownVillage"
              margin="normal"
            />
            <TextField
              label="House Number/Building Name"
              value={formState.proprietorHouseNumberBuildingName}
              onChange={handleChange}
              fullWidth
              name="proprietorHouseNumberBuildingName"
              margin="normal"
            />
            <TextField
              label="Street Name"
              value={formState.proprietorStreetName}
              onChange={handleChange}
              fullWidth
              name="proprietorStreetName"
              margin="normal"
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Means of Identification of the Proprietor (current and valid)
            </Typography>
            <TextField
              label="Type"
              value={formState.idCardType}
              onChange={handleChange}
              fullWidth
              name="idCardType"
              margin="normal"
            />
            <TextField
              label="Identity Number"
              value={formState.idCardNumber}
              onChange={handleChange}
              fullWidth
              name="idCardNumber"
              margin="normal"
            />
            <p style={{ marginTop: 30 }}>Means of Identification (pdf format):</p>
            <div style={{ display: "flex", alignItems: "center" }}>
            <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
            <p style={{ padding: 0, margin: 0, color: "gray" }}>  Any of: Permanent voters card,  Drivers Licence, National ID (NIN) slip, International Passport.</p>
          </div>
          <br/>
            <Input
              fullWidth
              type="file"
              onChange={handleFilePick("idCardCopy")}
            />
            <p style={{ marginTop: 30 }}>Passport Photograph of Proprietor:</p>
            <Input
              fullWidth
              type="file"
              onChange={handleFilePick("proprietorPassport")}
            />
            <p style={{ marginTop: 30 }}>Specimen Signature of Proprietor (on a white paper):</p>
            <Input
              fullWidth
              type="file"
              onChange={handleFilePick("proprietorSignature")}
            />
          <br/>
          <br/>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={HandleModal}
              style={{ margin: "30px 0", background: "var(--nyd-secondary)" }}
            >
              Submit
            </Button>
          </Box>
        </div>
      }
    </>
  );
};

export default BusinessNameRegistrationForm;
