import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RiMenu4Fill } from "react-icons/ri";

import { AiOutlineClose } from "react-icons/ai";
import Logo from "../assets/logo_white.png"



const Navbar = ({showMobileNav,setShowMobileNav}) => {

  return (
    <header className="nyd__header container-fluid" style={{position:"fixed", zIndex: 1030,top:0,left:0,right:0}}>
      <nav className="nyd__nav nyd-page-wrapper">
        <NavLink to="/"  aria-current="page" className="__logo nyd__nav--brand nuxt-link-exact-active nuxt-link-active">
          <img src={Logo} alt=""  style={{width:"50%",height:"auto"}}/>
          {/* logo */}
        </NavLink>
        <div className="d-none d-lg-block">
          <ul className="nyd__nav-links">
            <li className="nyd__nav-link-item">
              <div className="dropdown nyd-dropdown nyd-dropdown--nav">
                <a href="/services"  aria-expanded="false" className="nyd__nav-link dropdown-toggle">
                  Services
                </a>
                <NavLink to={"/about-us"}  aria-expanded="false" className="nyd__nav-link ">
                  About Us
                  {/* <i className="nyd-dropdown-icon bi bi-chevron-down text-is--primary"></i> */}
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-start">
                  <li>
                    <NavLink to="/" className="dropdown-item">CAC Registration</NavLink>
                  </li>
                  <li>
                    <NavLink to="/" className="dropdown-item">CAC Registration</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nyd__nav-link-item">
              <NavLink  to={'/privacy-policy'} className="nyd__nav-link">Privacy Policy</NavLink>
            </li>
            <li className="nyd__nav-link-item">
              <NavLink to="/terms-of-condition" className="nyd__nav-link">Terms of service</NavLink>
            </li>
            <li className="nyd__nav-link-item">
              <NavLink to="/contact-us" className="nyd__nav-link">Contact Us</NavLink>
            </li>
            {/* <li className="nyd__nav-link-item">
              <NavLink to="/complain" className="nyd__nav-link">Create Ticket</NavLink>
            </li> */}
          </ul>
        </div>
        <div className="d-none d-lg-block">
          <NavLink to="/login" className="nyd-button nyd-button--primary">
            Login
          </NavLink>
        </div>
        <div className="d-block d-lg-none">
          <div>
           
            <div className="nyd__mobile-nav-toggle"  onClick={()=>setShowMobileNav(!showMobileNav)}>
      {showMobileNav ? <AiOutlineClose/> : <RiMenu4Fill />}
      </div>
          </div>
        </div>
      {/* {showMobileNav &&  <div style={{position:"absolute",width:"100%",left:0,top:65}} className='bg--white'> */}
      {showMobileNav &&  <div  className={`bg--white ${showMobileNav ? "show_side_bar" : "close_side_bar"}`}>
          <ul className='my-ul'>
            <li className='my-link'>
            <NavLink to="/services" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
           Services 
          </NavLink>
            </li> 
             <li>
            <NavLink to="/about-us" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
            About us
          </NavLink>
            </li> 
             <li>
            <NavLink to="/privacy-policy" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
        Privacy Policy
          </NavLink>
            </li>
              <li>
            <NavLink to="/terms-of-condition" style={{color:"#08384e",textDecoration:"none"}} onClick={()=>setShowMobileNav(false)}>
            Terms of Service
          </NavLink>
            </li>  
            <li>
            <NavLink to="/contact-us" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
            Contact Us
          </NavLink>
          </li> 
            <li>
            <NavLink to="/login" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
            Login
          </NavLink>
          </li>
          {/* <li>
          <NavLink to="/complain" style={{color:"#08384e",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
            Create Ticket
          </NavLink>
            </li>  */}
            <li>
            <NavLink to="/signup" style={{color:"#2cb674",textDecoration:"none"}}onClick={()=>setShowMobileNav(false)}>
            Get Started
          </NavLink>   </li>
            
            
          </ul>
        </div>}
      </nav>
    </header>
  );
};

export default Navbar;
