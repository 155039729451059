import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { SiGnuprivacyguard } from "react-icons/si";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
const ResetPassword = () => {
  let token = new URLSearchParams(useLocation().search).get("token");
  const navigate = useNavigate();

  const [state, setState] = useState({
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const HandleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      if (state.email !== "" && state.password !== "") {
        let response = await axios.post(
          "https://server.easycomply.org/reset-password",
          { ...state },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:`Bearer ${token}`
            },
          }
        );

        setLoading(false);
        toast("Success");
        navigate("/login");
      } else {
        setLoading(false);
        setError("All filed are required");
      }
    } catch (error) {
      toast.error(error?.response?.data ? error.response.data : error.message);
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          let response = await axios.post(
            "https://server.easycomply.org/verify-link",
            { token },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:`Bearer ${token}`
              },
            }
          );
          setShowForm(true);
          setVerifying(false);
        } catch (error) {
          toast.error(error?.response ? error.response.data : error.message);
          setVerifying(false);
        }
      }
    })();
  }, []);

  return (
    <>
      {!showForm ? (
        <div
          className="nyd-page-wrapper my-center-item __wrapper"
          style={{ marginTop: 100,height:"90vh",flexDirection:"column" }}
        >
          <p>{verifying ? 'Verifying Link...' : 'Link verification Failed.'}</p>
          <p>{!verifying && 'Please check if you clicked the link sent to your mail.'}</p>
        </div>
      ) : showForm ?(
        <section
          className="nyd-page-wrapper my-center-item __wrapper"
          style={{ marginTop: 100 }}
        >
          <div
            className="my-center-item my-form-wrapper"
            style={{ width: "60%", marginBottom: 40 }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginBottom: 30,
                }}
              >
                <div
                  className="bg--secondary"
                  style={{ padding: 20, borderRadius: 100, margin: "20px 0" }}
                >
                  <SiGnuprivacyguard color="white" size={40} />
                </div>
                <p>Change Password</p>
              </div>

              <TextField
                label="New Password"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                variant="outlined"
                value={state.newPassword}
                onChange={(e) =>
                  setState({ ...state, [e.target.name]: e.target.value })
                }
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 20 }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <MdOutlineRemoveRedEye />
                        ) : (
                          <FaRegEyeSlash />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {error && <p style={{ color: "red" }}>{error}</p>}

              <button
                className="nyd-button nyd-button--primary"
                onClick={HandleSubmit}
              >
                {loading ? "Processing..." : "Submit"}
              </button>
            </div>
          </div>
        </section>
      ):''}
    </>
  );
};

export default ResetPassword;
