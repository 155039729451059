import React,{useState} from 'react'
import { TextField } from '@mui/material'
import axiosInstance from '../../utills/axios'
import { toast } from 'react-toastify'

const Ticket = ()=>{


    const [state, setState] = useState({
      subject:"",
      message:""
    })
    const [loading,setLoading] = useState(false)
  
    const HandleSubmit= async()=>{
try {
  

  setLoading(true)
    let response = await axiosInstance.post("/complain",state)
    if (response.status === 200) {
      // Handle successful submission
      setLoading(false)
      toast.success("complain successfully submitted");
      setState({
        subject:"",
        message:""
      })
      
    } else {
      setLoading(false)
      toast.error("Submission failed")
    }
  } catch (error) {
    toast.error(error.message)

  }
  
  
    }
return(
    <section style={{display:"flex",justifyContent:"center",alignContent:"center",marginTop:80,width:"100%"}}>
        <div style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"center",alignItems:"center"}}>
       <div>
       <h4 style={{textAlign:"center"}}>Create Ticket</h4>
        <p className="complain-text" style={{textAlign:"center",color:'gray'}}><b>Have an issues to complain about, use the box below.</b></p>
        </div> 

        <div className="my-center-item my-form-wrapper" style={{width:"60%",marginBottom:40,flexDirection:"column",justifyContent:"center",marginTop:"50px"}}>
        
    
      <TextField
        label="Subject"
        name="subject"
        type="text"
        variant="outlined"
        value={state.subject}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      <TextField
        label="Message"
        type="text"
         name="message"
        variant="outlined"
        value={state.message}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      <button className="nyd-button nyd-button--primary" onClick={HandleSubmit}>{loading ? "Sending..." : "Submit"}</button>
      </div>
  
      </div>
    
    

    </section>
)
}

export default Ticket