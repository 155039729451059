import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { SiGnuprivacyguard } from "react-icons/si";
import { NavLink,useNavigate } from "react-router-dom";

import axios from "axios"
import { toast } from 'react-toastify';

const Signup = () => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    fullName:"",
    email:"",
    password:"",
    phone:""
  })
  const [error,setError] = useState("")
  const [loading,setLoading] = useState(false)

  const HandleSubmit= async ()=>{
    console.log(state)
    setLoading(true)
    setError("")
    try {
      if(state.fullName !== "" && state.email !== "" && state.phone !== "" && state.password !== ""){
      let response = await axios.post("https://server.easycomply.org/register",{...state},{
      headers:{
        "Content-Type":"application/json", 
      }
    })
    setLoading(false);
    toast(response.data)
    navigate("/login")

  }else{
    setLoading(false)
      setError("All filed are required");
    }
    } catch (error) {
      toast.error(error?.response?.data ? error.response.data : error.message)
    }
   
  }
  return (
    <>
    <section className="nyd-page-wrapper my-center-item __wrapper" style={{marginTop:100}}>
        <div className="my-center-item my-form-wrapper" style={{width:"60%",marginBottom:40}}>
        <div style={{width:"100%"}}>
        <div style={{alignItems:"center",width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",marginBottom:30}}>
            <div className="bg--secondary" style={{padding:20,borderRadius:100,margin:"20px 0"}}>
            <SiGnuprivacyguard color="white" size={40}/>
            </div>
            <p>Create Account To Get Started</p>
            </div>
      <TextField
      className="input-margin-bottom"
        label="FullName"
        name="fullName"
        variant="outlined"
        value={state.fullName}
        style={{marginBottom:20}}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      <TextField
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        value={state.email}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      /> 
      <TextField
        label="Phone Number"
        name="phone"
        type="tell"
        variant="outlined"
        value={state.phone}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      <TextField
        label="Password"
        type="password"
         name="password"
        variant="outlined"
        value={state.password}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
      {error && <p style={{color:"red"}}>{error}</p>}
      <p className="cursor--pointer" style={{textAlign:"right"}}>Already Have An Account? <span className="text-is--primary "><NavLink className="text-is--primary" to="/login">Login</NavLink></span></p>
      <button className="nyd-button nyd-button--primary" onClick={HandleSubmit}>{loading ? "processing..." : "Submit"}</button>
      </div>
      </div>
      </section>
    </>
  );
};

export default Signup;
