import React, { useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  Input,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from "../pages/dashboard";
import axiosInstance from "../utills/axios";
import { toast } from "react-toastify";
import { useGlobalContext } from "../utills/store";
import Admin from "../pages/dashboard/admin";

const initialState = {
  deedOfAssignment: false,
  surveyPlan: false,
  purchaseReceiptAndStampDuty: false,
  evidenceOfTaxPayment: false,
  passport: false,
  contactEmail: "",
  contactPhone: "",
};
const COFODocumentRegistrationForm = () => {
  const [formState, setFormState] = useState(initialState);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactErrormessage, setContactErrormessage] = useState("");
  const { user } = useGlobalContext();

  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const HandleModal = () => {
    if (!Object.values(formState).some((value) => value)) {
      setErrorMessage("At least one document must be selected");
      return;
    }

    setOpenModal(true);
  };

  const handleSubmit = async () => {
    if (formState.contactEmail === "" || formState.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/cofo-registration",
        formState
      );
      toast.success(response.data.message);
      setIsLoading(false);
      setFormState(initialState);
      setOpenModal(false);
    } catch (error) {
      setErrorMessage("Failed to submit registration. Please try again.");
    }
    // Handle the form submission, for example, send data to the server
  };

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
    {user.role === "admin" ? (
        <Admin service={"COFO Registration"} route={"cofo-registration"} />
      ) : (
        <>
      <p
        style={{
          color: "#08384e",
          fontSize: 20,
          marginTop: "4rem",
          width: "100%",
          textAlign: "left",
        }}
      >
        {Salutation()},<span style={{ color: "black" }}><span style={{color:"black"}}> {(user?.fullName?.split(" ")[0])}</span></span>
      </p>
      <p><b>{"Requirement for certificate of occupancy (C OF O)"} below.</b></p>
     
          <Modal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box className="contact_box">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                style={{ textAlign: "center", margin: "30px 0" }}
              >
                Contact Information
              </Typography>
              <div style={{ width: "100%" }}>
                <TextField
                  label="Phone"
                  type="tel"
                  name="contactPhone"
                  variant="outlined"
                  value={formState.contactPhone}
                  onChange={(e) =>
                    setFormState({ ...formState, contactPhone: e.target.value })
                  }
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "GrayText" },
                  }}
                />
                <TextField
                  label="Email"
                  type="email"
                  name="contactEmail"
                  variant="outlined"
                  value={formState.contactEmail}
                  onChange={(e) =>
                    setFormState({ ...formState, contactEmail: e.target.value })
                  }
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 20 }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{
                    style: { fontSize: 15, color: "GrayText" },
                  }}
                />
                {contactErrormessage ? (
                  <Typography variant="body2" color="error">
                    {contactErrormessage}
                  </Typography>
                ) : null}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  style={{
                    marginTop: "16px",
                    background: "var(--nyd-secondary)",
                  }}
                >
                  {isLoading ? "Please wait..." : "Proceed"}
                </Button>

                <p style={{ marginTop: "20px" }}>
                  Note: An agent will be assigned to you shortly.
                </p>
              </div>
            </Box>
          </Modal>
          <Box sx={{ padding: "16px" }}>
            <p>kindly indicate which of the below documents you have.</p>

            <FormControlLabel
              control={
                <Checkbox
                  name="deedOfAssignment"
                  checked={formState.deedOfAssignment}
                  onChange={handleCheckboxChange}
                />
              }
              label="4 copies of Deed of Assignment originally signed"
            />
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  name="passport"
                  checked={formState.passport}
                  onChange={handleCheckboxChange}
                />
              }
              label="Passport copy of the owner 5 by 5 white background"
            />
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  name="surveyPlan"
                  checked={formState.surveyPlan}
                  onChange={handleCheckboxChange}
                />
              }
              label="4 copies of a recent survey plan (1 original in cloth and 3 paper copies)"
            />
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  name="purchaseReceiptAndStampDuty"
                  checked={formState.purchaseReceiptAndStampDuty}
                  onChange={handleCheckboxChange}
                />
              }
              label="Purchase Receipt & Stamp Duty"
            />
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  name="evidenceOfTaxPayment"
                  checked={formState.evidenceOfTaxPayment}
                  onChange={handleCheckboxChange}
                />
              }
              label="Evidence of Tax Payment (if any)"
            />

            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "30px 0 ",
              }}
            >
              <FaInfoCircle
                size={14}
                style={{ marginRight: 4, color: "gray" }}
              />
              <p style={{ padding: 0, margin: 0, color: "gray" }}>
                {" "}
                Timeline is usually 18 - 24 months
              </p>
            </div>

            <Button
              variant="contained"
              onClick={HandleModal}
              style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
            >
              Submit
            </Button>
          </Box>{" "}
        </>
      )}
    </div>
  );
};

export default COFODocumentRegistrationForm;
