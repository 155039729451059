import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Input,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
// import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from "../pages/dashboard";
import axiosInstance from "../utills/axios";
import { toast } from "react-toastify";
import Admin from "../pages/dashboard/admin";
import { useGlobalContext } from "../utills/store";

const initialData =  {
  selectedService: "",
  details: "",
  cacCertificationFile: null,
  memartFile: null,
  statusReportFile: null,
  contactEmail:'',
  contactPhone:''
}
const PostIncorporationServicesForm = () => {
  const [formData, setFormData] = useState(initialData);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactErrormessage, setContactErrormessage] = useState("");
  const { user } = useGlobalContext();

  const handleFilePick = (fieldName) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        setFormData({ ...formData, [fieldName]: file });
      } else {
        setErrorMessage("Only PDF files are allowed");
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };

  const handleSubmit = async () => {
    const { selectedService, details, cacCertificationFile, memartFile, statusReportFile, contactEmail, contactPhone } = formData;
    if (!selectedService || !details || !cacCertificationFile || !statusReportFile || !contactEmail || !contactPhone) {
      setContactErrormessage("All fields are required");
      return;
    }

    setIsLoading(true);

    try {
      if (formData.contactEmail === "" || formData.contactPhone === "") {
        setContactErrormessage("All fields are required!");
        return false;
      }
      const response = await axiosInstance.post('/post-incorporated-trustee', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.status === 200) {
        // Handle successful submission
        toast.success("Incorporated trustee filed successfully");
        setFormData(initialData)
      } else {
        toast.error("Submission failed")
        
        setErrorMessage("Submission failed");
      }
    } catch (error) {
      setErrorMessage("Error submitting the form");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const HandleModal = () => {
    const isAnyFieldEmpty = Object.keys(formData).some(
      key => key !== 'contactEmail' && key !== 'contactPhone' && key !== 'memartFile' && !formData[key]
    );

    if (isAnyFieldEmpty) {
      setErrorMessage("All fields are required");
      return;
    }
   
    setOpenModal(true);
  };

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
         { user.role === "admin" ? <Admin service={"Post incorporated trustee"} route={"post-incorporated-trustee"}/> : <>

      <p style={{ color: "#08384e", fontSize: 20, marginTop: "4rem", width: "100%", textAlign: "left" }}>
       <span style={{ color: "black" }}> {Salutation()},<span style={{color:"black"}}> {(user?.fullName?.split(" ")[0])} !</span>
        </span>
      </p>
      <p><b>{"Complete Post Incorporation Services  Form below."}</b></p>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Contact Information
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Phone"
              type="tel"
              name="contactPhone"
              variant="outlined"
              value={formData.contactPhone}
              onChange={handleInputChange('contactPhone')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <TextField
              label="Email"
              type="email"
              name="contactEmail"
              variant="outlined"
              value={formData.contactEmail}
              onChange={handleInputChange('contactEmail')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
             {contactErrormessage ? (
          <Typography variant="body2" color="error">
            {contactErrormessage}
          </Typography>
        ) : null}
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading}
              style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
            >
              {isLoading ? 'Please wait...' : 'Proceed'}
            </Button>
           
            <p style={{marginTop:"20px"}}>Note: An agent will be assigned to you shortly.</p>
          </div>
        </Box>
      </Modal>
      <Box sx={{ padding: "0px" }}>
       
        <p style={{ marginTop: "30px" }}>Select Service</p>
        <Select
          value={formData.selectedService}
          onChange={(event) => setFormData({ ...formData, selectedService: event.target.value })}
          fullWidth
          margin="normal"
        >
          <MenuItem value="">Select Service</MenuItem>
          <MenuItem value="Alteration of Memorandum">Alteration of Memorandum</MenuItem>
          <MenuItem value="Annual Returns">Annual Returns</MenuItem>
          <MenuItem value="Appointment of Administrator, Receiver, Receiver Manager or Supervisor">
            Appointment of Administrator, Receiver, Receiver Manager or Supervisor
          </MenuItem>
          <MenuItem value="Certified True Copy/Certified Extracts">Certified True Copy/Certified Extracts</MenuItem>
          <MenuItem value="Change of Company Secretary">Change of Company Secretary</MenuItem>
          <MenuItem value="Change of Director">Change of Director</MenuItem>
          <MenuItem value="Change in Allotment of Shares">Change in Allotment of Shares</MenuItem>
          <MenuItem value="Change of Name">Change of Name</MenuItem>
          <MenuItem value="Change of Registered Address">Change of Registered Address</MenuItem>
          <MenuItem value="Company Search">Company Search</MenuItem>
          <MenuItem value="Conversion/Re-registration of Company">Conversion/Re-registration of Company</MenuItem>
          <MenuItem value="Notice/Change of Person with Significant Control">Notice/Change of Person with Significant Control</MenuItem>
          <MenuItem value="Notice of Change in Details of Person with Significant Control">
            Notice of Change in Details of Person with Significant Control
          </MenuItem>
          <MenuItem value="Notice of Cessation to Act as a Receiver, Receiver Manager, Administrator, or Supervisor">
            Notice of Cessation to Act as a Receiver, Receiver Manager, Administrator, or Supervisor
          </MenuItem>
          <MenuItem value="Notice of Cessation of Person with Significant Control">
            Notice of Cessation of Person with Significant Control
          </MenuItem>
          <MenuItem value="Increase in Issued Share Capital">Increase in Issued Share Capital</MenuItem>
          <MenuItem value="Notice of Change in Particulars of Director">Notice of Change in Particulars of Director</MenuItem>
          <MenuItem value="Notice of Change in Particulars of Shareholders">Notice of Change in Particulars of Shareholders</MenuItem>
          <MenuItem value="Reduction in Issued Share Capital">Reduction in Issued Share Capital</MenuItem>
          <MenuItem value="Registration of Charges">Registration of Charges</MenuItem>
          <MenuItem value="Statement of Satisfaction in Whole or in Part of a Charge">
            Statement of Satisfaction in Whole or in Part of a Charge
          </MenuItem>
          <MenuItem value="Transmission Transfer Surrender New Allotment">
            Transmission Transfer Surrender New Allotment
          </MenuItem>
          <MenuItem value="Liquidation/Winding Up">Liquidation/Winding Up</MenuItem>
          <MenuItem value="Request for Letter of Good Standing">Request for Letter of Good Standing</MenuItem>
          <MenuItem value="Statement of Satisfaction in Whole or in Part of a Charge">
            Statement of Satisfaction in Whole or in Part of a Charge
          </MenuItem>
          <MenuItem value="Fourteenth Schedule">Fourteenth Schedule</MenuItem>
        </Select>

        <TextField
          label="Service Details"
          value={formData.details}
          onChange={(event) => setFormData({ ...formData, details: event.target.value })}
          fullWidth
          multiline
          rows={4}
          placeholder="Briefly explain the service you require"
          margin="normal"
        />

        <p style={{ marginTop: 20 }}>CAC Certificate (PDF):</p>
        <Input
          type="file"
          fullWidth
          onChange={handleFilePick("cacCertificationFile")}
        />

        <p style={{ marginTop: 20 }}>Memart (PDF):<span style={{color:"grey"}}>(optional for business Name)</span></p>
        <Input
          type="file"
          fullWidth
          onChange={handleFilePick("memartFile")}
        />

        <p style={{ marginTop: 20 }}>Status Report (PDF):</p>
        <Input
          type="file"
          fullWidth
          onChange={handleFilePick("statusReportFile")}
        />

        {errorMessage ? (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        ) : null}

        <Button
          variant="contained"
          onClick={HandleModal}
          style={{ marginTop: "16px", background: "var(--nyd-secondary)" }}
        >
          Submit
        </Button>
      </Box></>}
    </div>
  );
};

export default PostIncorporationServicesForm;
