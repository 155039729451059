import React,{useState} from "react";
import heroRight from "../assets/hero_.jpg";
import { NavLink } from "react-router-dom";


const Services = ()=>{
    const [show,setShow] = useState(false)

    const services = [
        {
          title: "Business Name",
          description:"Business name registration provides legal protection for your brand, gives you credibility, promotes partnership and can help you secure financing such as government and commercial loans, grants, foreign aid and many more."    },
        {
          title: "Company Registration",
          description:"Company registration gives room for Investment from sales of shares. It gives your clients the confidence to deal with your organization. You can open Bank Accounts in your company name. It also provides corporate Legal Liability Protection."    },
        {
          title: "Incorporated Trustee",
          description:"Incorporated Trustees e.g Charity Foundations, NGO, Religious Organizations, Associations, Community Groups etc are non-business and non-profit making organizations formed to facilitate acquisition of corporate personality to achieve their objectives."
        },
        {
          title: "Post Incorporation Services",
          // description:"After registering your Business Name, Companies or Incorporated Trustees, you may discover that there is the need to change certain things either as a result of new circumstances, mistakes or regulatory mandate.          "
          description:"Post-incorporation adjustments, necessitated by changing circumstances or regulations, offer benefits such as safeguarding perpetual existence, enabling ownership transfer, building credit, and facilitating changes in various aspects.  "
        },
        {
          title: "Trade Mark",
          description:"By Registering the trademark, a business can distinguish its product and service from its rivals. It helps to make a unique identity in the consumer's mind by using the mark consistently over time, which promotes brand reputation and loyalty."
        },
        {
          title: "C Of O Registration",
          description:"A Certificate of Occupancy is a legal document issued by the government that proves that a person owns land in Nigeria. The Government reserves the right to seize any plot of land or property without a C of O at any time without any compensation paid. "
        }, 
        {
          title: "Scuml Registration",
          description:"The SCUML certificate, issued by the EFCC, serves as evidence that an organization's bank account is not involved in money laundering activities, providing assurance and compliance with anti-money laundering regulations."
        }, 
        {
          title: "Money Lender’s License",
          description:"Being a licensed moneylender grants the right to take legal action for recovering loans. This ensures the flow of money, supporting business growth, investment, and job creation. In Nigeria, obtaining this license is mandatory. "
        }, 
        {
          title: "NAFDAC Registration",
          description:"NAFDAC ensures product quality and safety for public use. Registering your products demonstrates commitment to compliance, consumer safety, and builds trust, as it signifies adherence to regulatory standards and guidelines.   "
        },
        {
          title: "NewsPapper Publication",
          // description:"Newspapers publications are essential in ensuring informed societies by delivering accurate and timely information. They provide a platform for diverse opinions, foster public discourse, and hold authorities accountable. Reliable publications maintain public trust and contribute to education and cultural awareness. By adhering to high journalistic standards, they play a critical role in sustaining democracy and promoting transparency. "
          description:"A newspaper is a publication consisting of a number of large sheets of folded paper, on which news, advertisements, and other information is published."
        },
      ];
    return(
       <div style={{marginTop:150,display:"flex",justifyContent:"center",padding:"0 60px",width:"100%",paddingBottom:"40px"}}>
        <section>
            <h2 className="text-is--secondary" style={{textAlign:"center"}}>Our Services</h2>
        <p className="text-is--dark" style={{textAlign:"center"}}>           
            Our team of highly trained and experienced professionals <br/>and agents work diligently and tirelessly to ensure that you achieve regulatory compliance with EASE.     
        </p>
        <div className="row align-items-center col-xl-12 col-md-7"  data-aos="zoom-in-up">
              {/* <div className="col-xl-4 my-3"> */}
              {services.map((item, index) => (
                <div  key={index} className={`col-xl-4 my-3 show_ ` }>
                  <div className="card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                      }}
                    >
                      <img
                        src={heroRight}
                        className=""
                        alt="Card"
                        width={40}
                        height={40}
                        style={{ borderRadius: 100 }}
                      />
                    </div>
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        {item.title}
                      </h5>
                      <p
                        className="card-text"
                        style={{ color: "#57595A", fontSize: "12px" }}
                      >
                        {item.description}
                      </p>
                      <NavLink
                        to={"/login"}
                        className="nyd-button nyd-button--primary my-btn"
                        style={{ fontSize: 12 }}
                      >
                        Proceed
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
              {/* </div> */}

            
            </div>

        </section>
       </div>
    )
}

export default Services