import React, { useEffect, useState } from "react";
import "./mainDash.css";
import { useGlobalContext } from "../../utills/store";
import ResponsiveTable from "../../components/tabe";
import axiosInstance from "../../utills/axios";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Modal, Typography, TextField } from "@mui/material";
import Spinner from "../../utills/preloader";
import RenderObject from "../../components/previewObj";

export const Salutation = () => {
  const now = new Date();
  const currentHour = now.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};
export function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
}


const Admin = ({ route, service }) => {
  const {
    activeRegistration,
    setActiveRegistration,
    user,
  } = useGlobalContext();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const columns = [
    {
      name: "N/O",
      selector: (row, index) => index + 1,
    },
    {
      name: "Contact Email",
      selector: (row) => row.contactEmail,
    },
    {
      name: "Contact Phone",
      selector: (row) => row.contactPhone,
    },
    {
      name: "Transaction Ref",
      selector: (row) => row.transactionRef,
    },
    {
      name: "Service Name",
      selector: (row) => row.serviceName,
    },
    {
      name: "Created On",
      selector: (row) => formatDate(row.createdAt),
    },
    {
      name: "Request Details",
      selector: (row) => (
        <button
          onClick={() => {
            setPreviewContent(row.serviceDetails);
            setIsPreview(true);
          }}
          style={{
            border: "none",
            background: "#A060CC",
            color: "white",
            borderRadius: "50px",
            padding: "5px",
          }}
        >
          <MdOutlineRemoveRedEye /> View Form
        </button>
      ),
      // selector: row => formatDate(row.serviceDetails),
    },
  ];

  const GetRequest = async () => {
    let response = await axiosInstance.get(`/admin/${route}`);
    if (response.status === 200) {
      setData(response.data);
      setLoading(false);
    }
  };

  // useEffect(()=>{
  //   let active = localStorage.getItem("activeLink")
  // active !== null ? setActiveRegistration(active) : setActiveRegistration("Dashboard")

  // },[])

  useEffect(() => {
    GetRequest().then();
  }, []);

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
      <p
        style={{
          color: "#08384e",
          fontSize: 20,
          marginTop: "4rem",
          width: "100%",
          textAlign: "left",
        }}
      >
        {Salutation()},
        <span style={{ color: "black" }}>
          {" "}
          {user?.fullName?.split(" ")[0]} !
        </span>{" "}
      </p>
      <p>{service} Request History</p>
      {loading ? (
        <Spinner />
      ) : (
        <section>
         <Modal
            open={isPreview}
            onClose={() => setIsPreview(!isPreview)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <box className="preview_box">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                style={{ textAlign: "center", margin: "30px 0" }}
              >
                {service} Information
              </Typography>
              <div style={{ width: "100%" }}>
              <RenderObject obj={previewContent}/>
              </div>
            </box>
          </Modal>
          <ResponsiveTable data={data} columns={columns} />
        </section>
      )}
    </div>
  );
};

export default Admin;
