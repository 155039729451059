import React, { useState } from 'react';
import { Modal,TextField, Button, Typography, Input, Box } from '@mui/material';
import { FaInfoCircle } from "react-icons/fa";
import { Salutation } from '../pages/dashboard';
import axiosInstance from '../utills/axios';
import { toast } from 'react-toastify';
import UsePaystack from '../utills/paystack';
import { useGlobalContext } from '../utills/store';
import Admin from '../pages/dashboard/admin';

const initialFormData ={
  proposedNames: ['', '', ''],
  officialEmail: '',
  officeAddress: '',
  aimsAndObjectives: '',
  officialPhoneNumber: '',
  trusteeDetails: [
    {
      surname: '',
      firstName: '',
      otherNames: '',
      occupation: '',
      phone: '',
      email: '',
      sex: '',
      dob: '',
      residentialAddress: '',
      idCardType: '',
      idCardNumber: '',
      idCardScan: null,
      signature: null,
      passportPhoto: null,
    },
  ],
  secretaryDetails: {
    surname: '',
    firstName: '',
    otherNames: '',
    occupation: '',
    phone: '',
    email: '',
    sex: '',
    dob: '',
    residentialAddress: '',
    idCardType: '',
    idCardNumber: '',
    idCardScan: null,
    signature: null,
    passportPhoto: null,
  },
  chairmanName: '',
  contactPhone:'',
  contactEmail:''
}

const IncorporatedTrusteeRegistrationForm = () => {
  const { user } = useGlobalContext();

  const [contactErrormessage, setContactErrormessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [formData, setFormData] = useState(initialFormData);
  const handlePay = () => {
    if (formData.contactEmail === "" || formData.contactPhone === "") {
      setContactErrormessage("All fields are required!");
      return false;
    }
    setContactErrormessage("");
    setLoading(true);
    return true;
  };
  const [errorMessage, setErrorMessage] = useState('');

  const handleFilePick = (stateSetter) => async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result; // Get the full data URL
          stateSetter(base64String); // Set the full data URL
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    } catch (err) {
      setErrorMessage("Error picking the file");
    }
  };



  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleArrayInputChange = (index, field, value) => {
    const updatedArray = [...formData.proposedNames];
    updatedArray[index] = value;
    setFormData({ ...formData, proposedNames: updatedArray });
  };

  const handleTrusteeDetailsChange = (index, field, value) => {
    const updatedTrusteeDetails = [...formData.trusteeDetails];
    updatedTrusteeDetails[index][field] = value;
    setFormData({ ...formData, trusteeDetails: updatedTrusteeDetails });
  };

  const handleAddTrustee = () => {
    setFormData({
      ...formData,
      trusteeDetails: [
        ...formData.trusteeDetails,
        {
          surname: '',
          firstName: '',
          otherNames: '',
          occupation: '',
          phone: '',
          email: '',
          sex: '',
          dob: '',
          residentialAddress: '',
          idCardType: '',
          idCardNumber: '',
          idCardScan: null,
          signature: null,
          passportPhoto: null,
        },
      ],
    });
  };

  const handleRemoveTrustee = (index) => {
    const updatedTrusteeDetails = [...formData.trusteeDetails];
    updatedTrusteeDetails.splice(index, 1);
    setFormData({ ...formData, trusteeDetails: updatedTrusteeDetails });
  };
  const handleSubmit = ({reference}) => {
    setContactErrormessage("")
    setLoading(true)
    
    axiosInstance.post("/incorporated-trustee",{...formData,transactionRef:reference},{
        headers:{
          "Content-Type":"application/json"
        }
      }).then((res)=>{
        setLoading(false)
        toast(res.data);
        setOpenModal(false);
        setFormData(initialFormData);

      }).catch(err=>{
        setLoading(false)
        console.log(err)
        toast.error(err.message)
      })
  };

  const HandleModal = () => {
    if (
      formData.proposedNames.some((name) => !name) ||
      !formData.officialEmail ||
      !formData.officeAddress ||
      !formData.aimsAndObjectives ||
      !formData.officialPhoneNumber ||
      formData.trusteeDetails.some((trustee) => Object.values(trustee).some((value) => !value)) ||
      Object.values(formData.secretaryDetails).some((value) => !value) ||
      !formData.chairmanName
    ) {
      setErrorMessage('All fields are required');
      return;
    }
    setOpenModal(true)
    // Handle the form submission, for example, send data to the server
  };

  return (
    <div className="MainDash" style={{ padding: "0 20px" }}>
      {user.role === "admin" ? (
        <Admin service={"Incorporation Services"} route={"incorporated-trustee"} />
      ) :
    <>
      <p style={{ color: "#08384e", fontSize: 20, marginTop: "4rem", width: "100%", textAlign: "left" }}>
        {Salutation()},<span style={{ color: "black" }}> {(user?.fullName?.split(" ")[0])} !</span>
      </p>
      <p><b>{"Complete Incorporation Services Form below."}</b></p>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <box className="contact_box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            Contact Information
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              label="Phone"
              type="tel"
              name="contactPhone"
              variant="outlined"
              value={formData.contactPhone}
              onChange={handleInputChange('contactPhone')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
            <TextField
              label="Email"
              type="email"
              name="contactEmail"
              variant="outlined"
              value={formData.contactEmail}
              onChange={handleInputChange('contactEmail')}
              fullWidth
              autoComplete="off"
              style={{ marginBottom: 20 }}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
            />
         
            <UsePaystack onClose={()=>{console.log("closed"); setLoading(false);} } handlePay={handlePay} isLoading={loading} onSuccess={handleSubmit} email={formData.contactEmail} amount={165000}/>
            <p style={{marginTop:"20px"}}>Note: An agent will be assigned to you shortly.</p>
            { contactErrormessage && <p style={{color:"red"}}>{contactErrormessage}</p>}
          </div>
        </box>
      </Modal>
      <Box sx={{ padding: '0px' }}>
        <div style={{ margin: "30px 0" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
            <p style={{ padding: 0, margin: 0, color: "gray" }}> This is for NGO, NPO, Charity foundation etc.</p>
          </div>
          <p style={{ padding: 0, marginLeft: "20px", marginTop: "10px", color: "gray" }}> The higher the number of trustee the higher the charges.</p>
        </div>
      
        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 30 }}>
          Proposed Names
        </Typography>
        {formData.proposedNames.map((name, index) => (
          <TextField
            key={index}
            label={`Proposed Name ${index + 1}`}
            value={name}
            onChange={(event) => handleArrayInputChange(index, 'proposedNames', event.target.value)}
            fullWidth
            margin="normal"
          />
        ))}

        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 30 }}>
          Official Information
        </Typography>
        <TextField
          label="Official Email"
          value={formData.officialEmail}
          onChange={handleInputChange('officialEmail')}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Office Address"
          value={formData.officeAddress}
          onChange={handleInputChange('officeAddress')}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Aims and Objectives"
          value={formData.aimsAndObjectives}
          onChange={handleInputChange('aimsAndObjectives')}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <TextField
          label="Official Phone Number"
          value={formData.officialPhoneNumber}
          onChange={handleInputChange('officialPhoneNumber')}
          fullWidth
          margin="normal"
        />

        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 30 }}>
          Trustee Details
        </Typography>
        {formData.trusteeDetails.map((trustee, index) => (
          <div key={index} style={{ marginBottom: '16px' }}>
            <TextField
              label="Surname"
              value={trustee.surname}
              onChange={(event) => handleTrusteeDetailsChange(index, 'surname', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="First Name"
              value={trustee.firstName}
              onChange={(event) => handleTrusteeDetailsChange(index, 'firstName', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Other Names"
              value={trustee.otherNames}
              onChange={(event) => handleTrusteeDetailsChange(index, 'otherNames', event.target.value)}
              fullWidth
              margin="normal"
            />
         
            <TextField
              label="Occupation"
              value={trustee.occupation}
              onChange={(event) => handleTrusteeDetailsChange(index, 'occupation', event.target.value)}
              fullWidth
              margin="normal"
            />
               <TextField
              label="Phone Number"
              value={trustee.phone}
              onChange={(event) => handleTrusteeDetailsChange(index, 'phone', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={trustee.email}
              onChange={(event) => handleTrusteeDetailsChange(index, 'email', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Sex"
              value={trustee.sex}
              onChange={(event) => handleTrusteeDetailsChange(index, 'sex', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date of Birth"
              onFocus={(event)=> event.target.type = 'date'  }
            onBlur={(event)=> event.target.type = 'text' }
              value={trustee.dob}
              onChange={(event) => handleTrusteeDetailsChange(index, 'dob', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Residential Address"
              value={trustee.residentialAddress}
              onChange={(event) => handleTrusteeDetailsChange(index, 'residentialAddress', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="ID Card Type"
              value={trustee.idCardType}
              onChange={(event) => handleTrusteeDetailsChange(index, 'idCardType', event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="ID Card Number"
              value={trustee.idCardNumber}
              onChange={(event) => handleTrusteeDetailsChange(index, 'idCardNumber', event.target.value)}
              fullWidth
              margin="normal"
            />

            <p style={{ marginTop: 30 }}>ID Card Scan:</p>
            <Input type="file" fullWidth onChange={handleFilePick((file) => handleTrusteeDetailsChange(index, 'idCardScan', file))} />
          
            <p style={{ marginTop: 30 }}>Signature:</p>
            <Input type="file" fullWidth onChange={handleFilePick((file) => handleTrusteeDetailsChange(index, 'signature', file))} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
              <p style={{ padding: 0, margin: 0, color: "gray" }}> Signature should be signed on a plain white paper.</p>
            </div>

            <p style={{ marginTop: 30 }}>Passport Photo:</p>
            <Input type="file" fullWidth onChange={handleFilePick((file) => handleTrusteeDetailsChange(index, 'passportPhoto', file))} />
            <Button variant="outlined" onClick={() => handleRemoveTrustee(index)} style={{ marginTop: 30, color: "var(--nyd-secondary)", borderColor: "var(--nyd-secondary)" }}>
              Remove Trustee
            </Button>
          </div>
        ))}
        <Button variant="contained" onClick={handleAddTrustee} style={{ background: "var(--nyd-secondary)" }}>
          Add Trustee
        </Button>

        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 30 }}>
          Secretary Details
        </Typography>
        {Object.keys(formData.secretaryDetails)
          .filter((field) => !['idCardScan', 'signature', 'passportPhoto'].includes(field))
          .map((field) => (
            <TextField
              key={field}
              label={field === 'dob' ? "Date of Birth" : field === "phone" ? "Phone number" : field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              type={field === 'dob' ? 'date' : 'text'}
              InputLabelProps={field === 'dob' ? { shrink: true } : {}}
              value={formData.secretaryDetails[field]}
              onChange={(event) => setFormData({ ...formData, secretaryDetails: { ...formData.secretaryDetails, [field]: event.target.value } })}
              fullWidth
              margin="normal"
            />
        ))}


        <p style={{ marginTop: 30 }}>ID Card Scan:</p>
        <Input type="file" fullWidth onChange={handleFilePick((file) => setFormData({ ...formData, secretaryDetails: { ...formData.secretaryDetails, idCardScan: file } }))} />
      
        <p style={{ marginTop: 30 }}>Signature:</p>
        <Input type="file" fullWidth onChange={handleFilePick((file) => setFormData({ ...formData, secretaryDetails: { ...formData.secretaryDetails, signature: file } }))} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} title='What businesses it intends to engage in.' />
          <p style={{ padding: 0, margin: 0, color: "gray" }}> Signature should be signed on a plain white paper.</p>
        </div>

        <p style={{ marginTop: 30 }}>Passport Photo:</p>
        <Input type="file" fullWidth onChange={handleFilePick((file) => setFormData({ ...formData, secretaryDetails: { ...formData.secretaryDetails, passportPhoto: file } }))} />
        <div style={{ display: "flex", alignItems: "center", margin: "25px 0" }}>
          <FaInfoCircle size={14} style={{ marginRight: 4, color: "gray" }} />
          <p style={{ padding: 0, margin: 0, color: "gray" }}> Secretary can also be one of the trustees</p>
        </div>

        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 30 }}>
          Chairman Selection
        </Typography>
        <Typography>
          Indicate who the Chairman is among the trustees by providing the Chairman's name.
        </Typography>
        <TextField
          label="Chairman Name"
          value={formData.chairmanName}
          onChange={handleInputChange('chairmanName')}
          fullWidth
          margin="normal"
        />
        {errorMessage ? <Typography variant="body2" color="error">{errorMessage}</Typography> : null}

        <Button variant="contained" onClick={HandleModal} style={{ margin: '20px 0 35px 0', background: "var(--nyd-secondary)" }}>
          Submit
        </Button>
      </Box></>}
    </div>
  );
};

export default IncorporatedTrusteeRegistrationForm;
