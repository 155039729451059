import React from "react";
import { usePaystackPayment,PaystackButton } from 'react-paystack';

const UsePaystack = ({ onClose,onSuccess,email, amount, isLoading,handlePay}) => {
  const config = {
    reference: (new Date()).getTime().toString(),
    email: email,
    amount: amount * 100, // Paystack amount should be in kobo
    publicKey: 'pk_live_9848d7e9e3915893dfe5da8eb63efa7bf7bca5a8',
  };



 


const componentProps = {
    ...config,
    text: isLoading ? 'processing...':'proceed to pay',
    onSuccess: (reference) => onSuccess(reference),
    onClose: onClose,
};

const handleClick = () => {
    if (handlePay()) {
      document.querySelector('.paystack-button').click();
    }
  };

  return (
    <div>
    <button 
      className="nyd-button nyd-button--primary" 
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? 'please wait...' : 'Proceed to Pay'}
    </button>
    <PaystackButton 
      {...componentProps} 
      className="paystack-button nyd-button nyd-button--primary" 
    />
  </div>
  );
};

export default UsePaystack;
