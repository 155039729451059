import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions" style={{marginTop:100}}>
      <h1 style={{fontSize:20,textAlign:"center",padding:"20px 0"}}>EASY-COMPLY  TERMS AND CONDITIONS OF USE</h1>

      <section>
        <h2 style={{fontSize:18}}>1. About the Application</h2>
        <p>
          (a) Welcome to EASY COMPLY (the 'Application'). The Application offers Compliance Services
          (the 'Services'). Access to and use of the Application, or any of its associated Services, is
          provided by Easy-Comply Limited. Please read these terms and conditions (the 'Terms')
          carefully. By using the Easy Comply App, you signify that you have read, understood, and agree
          to be legally bound by the Terms. If you do not agree with the Terms, you must cease usage of
          the Application or any of its Services immediately.
        </p>
        <p>
          (b) Easy Comply reserves the right to review and change any of the Terms by updating this page
          at its sole discretion. When Easy Comply updates the Terms, it will use reasonable endeavours
          to provide you with notice of updates to the Terms. Any changes to the Terms take immediate
          effect from the date of their publication.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>2. Acceptance of the Terms</h2>
        <p>
          You accept the Terms by using the Application. You may also accept the Terms by clicking to
          accept or agree to the Terms where this option is made available to you by Easy Comply in the
          user interface.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>3. Registration to use the Services</h2>
        <p>
          (a) In order to access the Services, you must first register/Sign up for an account through the
          Application (the 'Account').
        </p>
        <p>
          (b) As part of the registration process, or as part of your continued use of the Services, you may
          be required to provide personal information about yourself (such as identification or contact
          details), including: (i) Email address (ii) Preferred username (iii) Address of yourself or
          members of the company (iv) Telephone number (v) Password (vi) Description/of business (vii)
          upload confidential or private documents etc.
        </p>
        <p>
          (c) You warrant that any information you give to Easy Comply in the course of completing the
          registration process or continued use of the services, will always be accurate, correct and up to
          date.
        </p>
        <p>
          (d) Once you have completed the registration process, you will be a registered user of the
          Application ('User') and agree to be bound by the Terms.
        </p>
        <p>
          (e) You may not use the Services and may not accept the Terms if you are not of legal age to
          form a binding contract with Easy Comply.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>4. Your obligations as a User</h2>
        <p>
          (a) As a User, you agree to comply with the following:
        </p>
        <p>
          (i) you will use the Services only for purposes that are permitted by:
          (i) the Terms; and
          (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant
          jurisdictions;
        </p>
        <p>
          (b) you have the sole responsibility for protecting the confidentiality of your password and/or email
          address.
        </p>
        <p>
          (c) any use of your registration information by any other person, or third parties, is strictly prohibited.
          You agree to immediately notify Easy Comply of any unauthorised use of your password or email
          address or any breach of security of which you have become aware;
        </p>
        <p>
          (d) access and use of the Application allow for the sole use of the Application by you for the purposes
          of Easy Comply providing the Services;
        </p>
        <p>
          (e) you will not use the Services or the Application in connection with any commercial endeavours
          except those that are specifically endorsed or approved by the management of Easy Comply;
        </p>
        <p>
          (f) you shall not use the App for any purpose which is in any manner inconsistent with the App Terms
          or which would be unlawful or fraudulent such as hacking into, inserting malicious code, or harmful
          data, into the App, or any connected operating system
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>5. Payment</h2>
        <p>
          (a) You understand that use of Easy Comply’s Services may result in charges to you for the services.
          Charges paid by you are final and non-refundable unless otherwise determined by Easy Comply
        </p>
        <p>
          (b) You may make payment for the Services (the 'Services Fee') by way of Credit Card Payment ('Credit
          Card') or bank transfer to the provided account details.
        </p>
        <p>
          (c) All payments made in the course of your use of the Services are made using PAYSTACK.
        </p>
        <p>
          (d) In using the Easy Comply Application, the Services or when making any payment in relation to your
          use of the Services, you warrant that you have read, understood and agree to be bound by the
          PAYSTACK terms and conditions which are available on their Application.
        </p>
        <p>
          (e) You acknowledge and agree that where a request for the payment of the Services Fee is returned
          or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason,
          then you are liable for any costs, including banking fees and charges, associated with the Services Fee.
        </p>
        <p>
          (f) Fees are calculated by Easy Comply immediately upon receiving all relevant information pertaining
          to the Service(s) you require and calculation of costs for the same.
        </p>
        <p>
          (g) Easy Comply calculates its Users service fee on the basis of the type of services required which may
          be made in whole or in different parts (per each milestone) in accordance with the type of service(s)
          required
        </p>
        <p>
          (h) You agree and acknowledge that Easy Comply can vary the Services Fee at any time.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>6. Non-Negotiability and No Refund Policy</h2>
        <p>
          The Service Fees are non-negotiable and non-refundable except if the management of Easy Comply
          makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances (the
          'Refund').
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>7. Copyright and Intellectual Property</h2>
        <p>
          (a) The Application and Services of Easy Comply are subject to copyright. The material on the
          Application is protected by copyright under the laws of the Federal Republic of Nigeria and through
          international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and
          compilation of the Application (including but not limited to text, graphics, logos, button icons, video
          images, audio clips, Application, code, scripts, design elements and interactive features) or the
          Services are owned or controlled for these purposes, and are reserved by Easy Comply or its
          contributors.
        </p>
        <p>
          (b) All trademarks, service marks, and trade names are owned, registered, and/or licensed by Easy
          Comply, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are
          a User to:
        </p>
        <p>
          (i) use the Application pursuant to the Terms;
        </p>
        <p>
          (ii) copy and store the Application and the material contained in the Easy Comply Application in your
          device's cache memory.
        </p>
        <p>
          However, Easy Comply does not grant you any other rights whatsoever in relation to the Application
          or the Services. All other rights are expressly reserved by Easy Comply
        </p>
        <p>
          (c) Easy Comply retains all rights, title, and interest in and to the Application and all related Services.
          Nothing you do on or in relation to the Application will transfer any:
        </p>
        <p>
          (i) business name, trading name, domain name, trade mark, industrial design, patent, registered
          design or copyright, or
        </p>
        <p>
          (ii) a right to use or exploit a business name, trading name, domain name, trade mark or industrial
          design, or
        </p>
        <p>
          (iii) a thing, system or process that is the subject of a patent, registered design or copyright (or an
          adaptation or modification of such a thing, system or process),
        </p>
        <p>
          to you.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>8. Privacy</h2>
        <p>
          Easy Comply takes your privacy seriously and any information provided through your use of the
          Application and/or Services are subject to Easy Comply's Privacy Policy, which is available on the
          Application.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>9. General Disclaimer</h2>
        <p>
          (a) Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions
          implied or imposed by the laws of the Federal Republic of Nigeria, which by law may not be limited or
          excluded.
        </p>
        <p>
          (b) Subject to this clause, and to the extent permitted by law:
        </p>
        <p>
          (i) all terms, guarantees, warranties, representations or conditions which are not expressly stated in
          the Terms are excluded; and
        </p>
        <p>
          (ii) Easy Comply will not be liable to you in any way for any direct or indirect loss or damages suffered
          or incurred by you regarding your inability to access the App for any reason, any error, omission, or
          misleading statement in the App, or the failure or delay of any transmission or receipt of instructions,
          or any viruses that may infect your computer equipment or other proprietary on account of your
          access to or use of the App or you accessing any materials on the App.
        </p>
        <p>
          (c) Use of the Application and the Services is at your own risk. Everything on the Application and the
          Services is provided to you "as is" and "as available" without warranty or condition of any kind. This
          includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:
        </p>
        <p>
          (i) failure of performance, error, omission, interruption, deletion, defect, failure to correct defects,
          delay in operation or transmission, computer virus or other harmful component, loss of data or
          communication line failure,
        </p>
        <p>
          (ii) costs incurred as a result of you using the Application, the Services of Easy Comply; and
        </p>
        <p>
          (iii) the Services or operation in respect to links which are provided for your convenience.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>10. Limitation of liability</h2>
        <p>
          (a) Easy Comply's total liability arising out of or in connection with the Services or these Terms,
          however arising, including under contract, tort (including negligence), in equity, under statute or
          otherwise, will not exceed the supply of the Services to you.
        </p>
        <p>
          (b) While Easy Comply has taken reasonable measures to ensure that its agents are trained
          professionals and are not criminally inclined; by making them go through a rigorous screening process
          before being recruited, you are expected to make reasonable efforts to monitor the activities of the
          agents while dealing with them.
        </p>
        <p>
          (c) You expressly understand and agree that Easy Comply, its affiliates, employees, agents,
          contributors and licensors shall not be liable to you for incompatibility of the App with any electronic
          device or any direct, indirect, incidental, special consequential or exemplary damages which may be
          incurred by you, however caused and under any theory of liability including any tangible or intangible
          loss.
        </p>
        <p>
          (d) Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their
          applicable international subsidiaries and affiliates will be third-party beneficiaries to this contract if
          you access the Services using Applications developed for Apple iOS, Android, Microsoft Windows, or
          Blackberry-powered mobile devices, respectively. These third-party beneficiaries are not parties to
          this contract and are not responsible for the provision or support of the Services in any manner. Your
          access to the Services using these devices is subject to terms set forth in the applicable third-party
          beneficiary’s terms of service.
        </p>
        <p>
          (e) By using this App, you accept all the risks associated with its use; inclusive of any health and safety
          risk associated with the use of a mobile or Web device in a work environment. Further, we do not
          warrant that the App will be uninterrupted or entirely error free. From time to time, the App may also
          be unavailable for use.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>11. Termination of Contract</h2>
        <p>
          (a) The Terms will continue to apply until terminated by either you or by Easy Comply as set out below.
        </p>
        <p>
          (b) Easy Comply may at any time, terminate the Terms with you if:
        </p>
        <p>
          (i) you have breached any provision of the Terms or intend to breach any provision;
        </p>
        <p>
          (ii) Easy Comply is required to do so by law;
        </p>
        <p>
          (iii) the provision of the Services to you by Easy Comply is, in the opinion of Easy Comply, no longer
          commercially viable.
        </p>
        <p>
          (c) Subject to local applicable laws, Easy Comply reserves the right to discontinue or cancel your
          membership at any time and may suspend or deny, in its sole discretion, your access to all or any
          portion of the Application or the Services without notice if you breach any provision of the Terms or
          any applicable law or if your conduct impacts Easy Comply's name or reputation or violates the rights
          of those of another party.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>12. Indemnity</h2>
        <p>
          You agree to indemnify Easy Comply, its owners, affiliates, employees, agents, contributors against
          any breach of the Terms.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>13. Dispute Resolution</h2>
        <p>
          (a) If a dispute arises out of or relates to the Terms, either party may not commence any Arbitration,
          Mediation or Court proceedings in relation to the dispute unless the following clauses have been
          complied with (except where urgent interlocutory relief is sought).
        </p>
        <p>
          (b) Notice: A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give
          written notice to the other party detailing the nature of the dispute, the desired outcome and the
          action required to settle the Dispute.
        </p>
        <p>
          (c) Resolution: On receipt of that notice ('Notice') by that other party, the parties to the Terms
          ('Parties') must:
        </p>
        <p>
          (i) Within 14 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by
          negotiation or such other means upon which they may mutually agree;
        </p>
        <p>
          (ii) If for any reason whatsoever, 7 days after the date of the Notice, the Dispute has not been resolved,
          the Parties must either agree upon selection of an arbitrator and the dispute shall be finally settled
          under the provisions of the Arbitration and Conciliation Act Cap A18, Laws of Federation of Nigeria,
          2004, by an Arbitration Panel of One who shall be a 5 year Member of the Chartered Institute of
          Arbitrators Nigeria, to be appointed by the Chairman of the Nigerian Bar Association Lagos Branch in
          accordance with Section 6 of the aforementioned Act to be approved by the Parties.
        </p>
        <p>
          (iii) The Parties are equally liable for the fees and reasonable expenses of an arbitrator and the cost of
          the venue of the arbitration and without limiting the foregoing undertake to pay any amounts
          requested by the arbitrator as a pre-condition to the arbitration commencing. The Parties must each
          pay their own costs associated with the arbitration;
        </p>
        <p>
          (iv) The mediation will be held in Lagos, Nigeria.
        </p>
        <p>
          (d) Termination of Mediation: If after 3 arbitration sessions have taken place and the Dispute has not
          been resolved, either Party may ask the Arbitrator to terminate the Arbitration and the Arbitrator
          must do so.
        </p>
        <p>
          (e) Either party may proceed to the appropriate court upon the failure of dispute resolution under
          Arbitration
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>14. Venue and Jurisdiction</h2>
        <p>
          The Services offered by Easy Comply is intended to be utilized by residents of Nigeria. In the event of
          any dispute arising out of or in relation to the Application, you agree that the exclusive venue for
          resolving any dispute shall be in the courts of the Federal Republic of Nigeria, sitting in Lagos State.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>15. Governing Law</h2>
        <p>
          The Terms are governed by the laws of the Federal Republic of Nigeria. Any dispute, controversy,
          proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the
          rights created hereby shall be governed, interpreted and construed by, under and pursuant to the
          laws of the Federal Republic of Nigeria. The validity of this governing law clause is not contested. The
          Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>16. Independent Legal Advice</h2>
        <p>
          Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both
          parties having taken the opportunity to obtain independent legal advice and declare the Terms are
          not against public policy on the grounds of inequality or bargaining power or general grounds of
          restraint of trade.
        </p>
      </section>

      <section>
        <h2 style={{fontSize:18}}>17. Severance</h2>
        <p>
          If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction,
          that part shall be severed and the rest of the Terms shall remain in force.
        </p>
      </section>
    </div>
  );
};



export default TermsAndConditions;
