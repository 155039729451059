import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { SiGnuprivacyguard } from "react-icons/si";
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../utills/store";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import axios from  "axios"
const ForgotPassword = () => {
  const { setIsLoggedIn ,setUser} = useGlobalContext();
  const navigate = useNavigate()


  const [state, setState] = useState({
    email:"",
  })
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState('');

  const HandleSubmit= async()=>{
    setLoading(true)
    setError("")
    try {
      if(state.email !== ""){
      let response = await axios.post("https://server.easycomply.org/forget-password",{...state},{
      headers:{
        "Content-Type":"application/json", 
      }
       })
      
       setLoading(false)
       toast("Password reset link sent !")
       navigate("/login")
  }else{
    setLoading(false)
      setError("All filed are required");
    }
    } catch (error) {
      toast.error(error?.response?.data ? error.response.data : error.message)
      setLoading(false)
    }
   


  }


  return (
    <>
    <section className="nyd-page-wrapper my-center-item __wrapper" style={{marginTop:100}}>
        <div className="my-center-item my-form-wrapper" style={{width:"60%",marginBottom:40}}>
        <div style={{width:"100%"}}>
        <div style={{alignItems:"center",width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",marginBottom:30}}>
            <div className="bg--secondary" style={{padding:20,borderRadius:100,margin:"20px 0"}}>
            <SiGnuprivacyguard color="white" size={40}/>
            </div>
            <p>Reset Password</p>
            </div>
    
      <TextField
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        value={state.email}
        onChange={(e) => setState({...state,[e.target.name]:e.target.value})}
        fullWidth
        autoComplete="off"
        style={{marginBottom:20}}
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
      />
   
      {error && <p style={{color:"red"}}>{error}</p>}
    
     <button className="nyd-button nyd-button--primary" onClick={HandleSubmit}>{loading ? "Processing..." : "Submit"}</button>
      </div>
      </div>
      </section>
    </>
  );
};

export default ForgotPassword;
