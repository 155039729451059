import React from 'react';
import logo from "../assets/logo_white.png"
import { NavLink } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="contact" className="nyd-footer bg--dark py-5 footer_bg">
      <div className="nyd-page-wrapper" >
        <div className="container-fluid">
          <div className="row align-items-start">
            <div className="col-lg-4 my-3">
              <div className="nyd-footer__brand mb-4">
                <img src={logo} alt="easycomply-logo" />
              </div>
              <div className="px-0 col-lg-11">
                <p className="nyd-body--smaller text-is--white text-is--light">
                Choose our compliance services for unwavering trust and legal integrity, <br/>safeguarding your business with professional and reliable expertise.
            
            </p>  </div>
            </div>
            <div className="col-lg-8 my-3">
              <div className="row">
                <div className="col-lg-4 my-3">
                  <h5 className="nyd-footer__section-title">Company</h5>
                  <div className="mt-4">
                    <div className="my-2">
                      <NavLink to="/about-us" className="nyd-footer__link">About Us</NavLink>
                    </div>
                    <div className="my-2">
                      <NavLink to={"/privacy-policy"} className="nyd-footer__link">Privacy policy</NavLink>
                    </div>
                    <div className="my-2">
                      <NavLink to={"/terms-of-condition"} className="nyd-footer__link">Terms of service</NavLink>
                    </div>
                    <div className="my-2">
                      <a href="#" className="nyd-footer__link">Contact us</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 my-3">
                  <h5 className="nyd-footer__section-title">Contact</h5>
                  <div className="mt-4">
                    <div className="my-2">
                      <a href="#!" className="nyd-footer__link">T: 08100027470</a>
                    </div>
                    <div className="my-2">
                      <a href="#!" className="nyd-footer__link">C: 09037739551</a>
                    </div>
                    <div className="my-2">
                      <a href="#!" className="nyd-footer__link">I: <span className="__cf_email__" data-cfemail="98f1f6fef7d8f6e1fcb6f7eaffb6f6ff">support@easycomply.org</span></a>
                    </div>
                    <div className="my-2">
                      <a href="#!" className="nyd-footer__link">E: <span className="__cf_email__" data-cfemail="22474c53574b505b624c5b460c4d50450c4c45">contact@easycomply.org</span></a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 my-3">
                  <h5 className="nyd-footer__section-title">Visit Us</h5>
                  <div className="mt-4">
                    <div className="my-2">
                      <p className="nyd-body--small text-is--white text-is--light">
                      Lagos State, Nigeria.
                      </p>
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 my-4 nyd-footer__socials">
            <div className="social-media">
        <a href="https://www.facebook.com/easy-comply" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="social-icon" />
        </a>
        <a href="https://twitter.com/Easy_complyltd" target="_blank" rel="noopener noreferrer">
          <FaTwitter className="social-icon" />
        </a>
        <a href="https://www.instagram.com/easy_comply" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="social-icon" />
        </a>
        <a href="https://www.linkedin.com/in/easycomply" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="social-icon" />
        </a>
      </div>
           </div>
          </div>
          <div className="nyd-footer__divider"></div>
          <p className="nyd-body--small text-is--white">
            © 2023 All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
